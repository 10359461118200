<template>
  <app-dialog
    v-model="dialogForProjectDetail"
    :value.sync="dialogForProjectDetail"
    :width="typeSumValuesLength ? '1024' : '637'"
    persistent
    content-class="v-dialog__form"
    @keydown.esc="dialogForProjectDetail = false ">
    <v-card>
      <v-card-title>
        <div class="d-flex align-center">
          <ProjectIcon color="#ffffff" />
          Project Details
        </div>
        <v-icon
          color="#fff"
          @click="dialogForProjectDetail = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="projectAnalyticsFrom"
          @submit.prevent="editProject">
          <v-row no-gutters>
            <v-col
              class="pa-0"
              :md="typeSumValuesLength ? 4 : 12"
              cols="12">
              <p class="small-p title-in-modal mb-1 mt-3">
                Project Name
              </p>
              <v-text-field
                v-model="projectDetailEdit.projectName"
                placeholder="Project Name"
                single-line
                filled
                dense
                autocomplete="off" />
              <p class="small-p title-in-modal mb-1 mt-3">
                Project Number
              </p>
              <v-text-field
                v-model="projectDetailEdit.number"
                placeholder="Project Number"
                single-line
                filled
                dense
                autocomplete="off" />
              <p class="small-p title-in-modal mb-1 mt-3">
                Base Budget
              </p>
              <v-text-field
                v-model="projectDetailEdit.baseBudget"
                placeholder="Base Budget"
                type="number"
                single-line
                filled
                dense
                autocomplete="off"
                :disabled="editProjectInfoDisabled"
                @keypress="isNumber" />
              <p class="small-p title-in-modal mb-1 mt-3">
                Project Phase
              </p>
              <v-text-field
                v-model="projectDetailEdit.phase"
                placeholder="Project Phase"
                single-line
                filled
                dense
                autocomplete="off" />
              <p class="small-p title-in-modal mb-1 mt-3">
                Location
              </p>
              <location-field
                v-if="dialogForProjectDetail"
                single-line
                filled
                dense
                :location-types="[TYPE_GEOCODE]"
                :disabled="editProjectInfoDisabled"
                :current-location="renderAddressText"
                @setLocationData="projectDetailEdit.location = $event"
                @setAddressData="projectDetailEdit.address = $event" />
              <p class="small-p title-in-modal mt-3 mb-1">
                Project area
              </p>
              <div class="d-flex">
                <v-text-field
                  v-model.number="projectDetailEdit.area"
                  placeholder="Project area"
                  type="number"
                  single-line
                  filled
                  dense
                  autocomplete="off"
                  :disabled="editProjectInfoDisabled"
                  @keypress="isNumber" />
                <ProjectsArea
                  :radio-model="projectDetailEdit.areaUnit"
                  @updateRadioModel="projectDetailEdit.areaUnit = $event" />
              </div>
              <p class="small-p title-in-modal mb-1 mt-3">
                {{ targetProjectAreaPlaceholder }}
              </p>
              <v-text-field
                v-model.number="projectDetailEdit.powerDensity"
                :placeholder="targetProjectAreaPlaceholder"
                type="number"
                single-line
                filled
                dense
                autocomplete="off"
                :disabled="editProjectInfoDisabled" />
              <template>
                <div class="d-flex align-items-center mt-6 mb-1">
                  <p class="small-p title-in-modal mb-0 align-self-center">
                    Source Library
                  </p>
                </div>
                <v-autocomplete
                  v-model="projectDetailEdit.sourceLibraryId"
                  :items="getCustomLibraries"
                  attach
                  :menu-props="{
                    maxHeight:'190px'
                  }"
                  disabled
                  item-text="name"
                  item-value="id" />
              </template>
              <p class="small-p title-in-modal mb-1 mt-6">
                Notes
              </p>
              <v-textarea
                v-model="projectDetailEdit.notes"
                :rows="3"
                filled
                dense
                no-resize
                :disabled="editProjectInfoDisabled" />
            </v-col>
            <v-col
              v-if="typeSumValuesLength"
              class="pa-0 mt-6 pl-3"
              md="8"
              cols="12">
              <v-row
                style="justify-content: space-evenly"
                class="ma-0">
                <div
                  style="max-width: 320px"
                  class="pr-4">
                  <p
                    class="small-p title-in-modal mb-0">
                    Current Budget Cost
                  </p>
                  <p
                    class="medium-p mt-3 font-weight-bold mb-0 overflow-dots text-center">
                    {{ $formatCurrency(getTotalCost) }}
                  </p>
                </div>
                <div style="max-width: 320px">
                  <p
                    class="small-p title-in-modal mb-0"
                    :style="{color: setBudgetConditionText === 'Over' ? 'var(--v-mainRed-base)' : 'var(--v-success-base)'}">
                    {{ setBudgetConditionText }} Budget
                  </p>
                  <p
                    class="medium-p mt-3 font-weight-bold mb-0 overflow-dots text-center">
                    {{ setBudgetConditionValue }}
                  </p>
                </div>
              </v-row>
              <PieChart
                v-if="typeSumValuesLength"
                :type-percentage="getTypeSumValues" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex row justify-end ma-0">
        <v-col
          class="pa-0 justify-sm-start justify-end d-flex"
          cols="12"
          md="3">
          <app-btn
            top
            :show-tooltip="!canWorkWithModify"
            :access="getModifyAccessData"
            color="lightBlue"
            @click="updateProject(getArchiveData, 'status')">
            Archive
          </app-btn>
        </v-col>
        <v-col
          class="pa-0 d-flex justify-end"
          cols="12"
          md="9">
          <div class="my-auto d-flex">
            <v-btn
              outlined
              color="lightBlue"
              class="mr-2"
              @click="dialogForProjectDetail = false">
              Cancel
            </v-btn>
            <app-btn
              top
              :show-tooltip="!getModifyAccessData.allowed"
              :access="getModifyAccessData"
              color="lightBlue"
              @click="editProject">
              Update
            </app-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </app-dialog>
</template>
<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import ProjectAggregationApi from '@/services/graphql/projectAggregation';
import Validations from '@/utils/validations';
import {
  accessHelper, getAreaUnitTitleByValue,
} from '@/utils';
import PieChart from '@/components/App/AppPieChart';
import ProjectsApi from '@/services/graphql/projects';
import ProjectsArea from '@/components/Projects/ProjectsArea';
import { ARCHIVED_PROJECT_REASON } from '@/constants/userPermissions';
import AppGoogleLocationField from '@/components/App/AppFields/AppGoogleLocationField';
import { TYPE_GEOCODE } from '@/constants/maps';
import { onSortingDocumentChangeSubscription as onSortingDocument } from '@/services/graphql/sortingDocumentSubscription';
import {
  ACTIVE, ARCHIVED,
} from '@/constants';
import { omit } from 'lodash';
export default {
  name: 'AppProjectDetailInfoModal',
  components: {
    PieChart,
    ProjectsArea,
    locationField: AppGoogleLocationField,
  },
  data: () => ({
    dialogForProjectDetail: false,
    id: null,
    baseBudgetForCalc: null,
    projectDetailEdit: {
      baseBudget: null,
      phase: '',
      notes: '',
      location: '',
      address: '',
      area: '',
      number: '',
      projectName: '',
      sourceLibraryId: '',
      powerDensity: '',
      areaUnit: '',
    },
    TYPE_GEOCODE,
  }),
  computed: {
    ...mapState(['showProjectInfoModal', 'projectId', 'role']),
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('ProjectAggregation', ['aggregationData']),
    ...mapGetters('Libraries', ['getCustomLibraries']),
    ...mapGetters('ProjectDetails', ['statusOfProject']),
    ...mapGetters('ProjectAggregation', ['getTypeSumValues', 'getTotalCost']),
    ...mapGetters(['premiumGroup', 'collaboratorGroup', 'userId']),
    ...mapGetters('UserRoles', ['canModifyProject']),
    renderAddress() {
      const { location, address } = this.projectDetailEdit;
      const addressObject = {
        location,
        address,
      };
      const paramToOmit = address ? 'location' : 'address';
      return omit(addressObject, [paramToOmit]);
    },
    renderAddressText() {
      const { location, address } = this.projectDetailEdit;
      return address?.formatted_address || location;
    },
    typeSumValuesLength() {
      return this.getTypeSumValues.length;
    },
    areaUnitTitle() {
      return getAreaUnitTitleByValue(this.projectDetailEdit?.areaUnit);
    },
    targetProjectAreaPlaceholder() {
      return `Target Project Power Density (W/${ this.areaUnitTitle })`;
    },
    canWorkWithModify() {
      return this.canModifyProject(this.role);
    },
    getModifyAccessData() {
      const reason = this.statusOfProject === ACTIVE ? this.canWorkWithModify?.reason : ARCHIVED_PROJECT_REASON;
      return accessHelper(!this.editProjectInfoDisabled, reason);
    },
    editProjectInfoDisabled() {
      return !(this.statusOfProject === ACTIVE && this.canWorkWithModify.allowed);
    },
    setBudgetConditionText() {
      return this.getTotalCost < this.baseBudgetForCalc ? 'Under' : 'Over';
    },
    setBudgetConditionValue() {
      const { baseBudgetForCalc, getTotalCost: totalCost } = this;
      const value = totalCost < baseBudgetForCalc
        ? baseBudgetForCalc - totalCost : totalCost - baseBudgetForCalc;
      return this.$formatCurrency(value);
    },
    getArchiveData() {
      return {
        ...this.getBaseData,
        status: ARCHIVED,
      };
    },
    getBaseData() {
      const { projectName, sourceLibraryId } = this.projectDetailEdit;
      const workspaceId = this.activeWorkspaceId;
      return {
        id: this.$route.params.id || this.projectId,
        status: this.detailProjectData.status,
        projectName,
        workspaceId,
        sourceLibraryId,
      };
    },
    isProjectCreator() {
      return this.userId === this.detailProjectData?.creator?.id;
    },
  },
  watch: {
    aggregationData(val) {
      if (val) {
        this.parseProjectAggregationData(val);
      }
    },
    dialogForProjectDetail(val) {
      if (!val) {
        this.clearForm();
      } else {
        this.getLibrariesList();
      }
    },
    detailProjectData(val) {
      if (val) {
        const { baseBudget, location, area, phase, number, projectName, sourceLibraryId, powerDensity, areaUnit, address } = val;
        this.projectDetailEdit.baseBudget = baseBudget;
        this.projectDetailEdit.location = location;
        this.projectDetailEdit.address = address;
        this.projectDetailEdit.area = area;
        this.projectDetailEdit.phase = phase;
        this.projectDetailEdit.number = number;
        this.baseBudgetForCalc = baseBudget;
        this.projectDetailEdit.projectName = projectName;
        this.projectDetailEdit.sourceLibraryId = sourceLibraryId;
        this.projectDetailEdit.powerDensity = powerDensity;
        this.projectDetailEdit.areaUnit = areaUnit;
      }
    },
    async showProjectInfoModal() {
      await this.getProjectById({
        id: this.$route.params.id || this.projectId,
        showModal: false,
      });
      this.dialogForProjectDetail = true;
      if (!this.editProjectInfoDisabled) {
        this.getProjectAggregation({
          projectId: this.$route.params.id || this.projectId,
        });
      }
    },
  },
  methods: {
    ...mapMutations({
      setDetailDataOfProject: 'ProjectDetails/setDetailDataOfProject',
      spinner: 'spinner',
      setAggregationData: 'ProjectAggregation/setAggregationData',
    }),
    ...mapActions({
      getLibrariesList: 'Libraries/getLibrariesList',
      getProjectById: 'ProjectDetails/getProjectById',
      handleError: 'handleError',
      getProjectAggregation: 'ProjectAggregation/getProjectAggregation',
      updateStatus: 'Projects/updateStatus',
    }),
    isNumber(evt) {
      Validations.isInteger(evt);
    },
    parseProjectAggregationData(data) {
      const { notes, id } = data || {
      };
      this.projectDetailEdit.notes = notes || '';
      this.id = id;
    },
    async updateProject(data, criteria) {
      const {
        spinner,
        getProjectById,
        getBaseData,
        getArchiveData,
        $route: route,
      } = this;
      spinner(true);
      try {
        if (criteria != 'updateProjectAggregationNotes') {
          await ProjectsApi.updateProject(criteria == 'status' ? getArchiveData : getBaseData);
          if (criteria == 'status') {
            if (route.name == 'projects') {
              this.dialogForProjectDetail = false;
              spinner(false);
              this.updateStatus(data);
            } else {
              await this.subscribeOnAcrhivingOfProject();
            }
          }
        }
        if (criteria != 'status') {
          const workspaceId = this.activeWorkspaceId;
          await ProjectAggregationApi[criteria]({
            ...data,
            workspaceId,
          });
          spinner(false);
        }
        if (criteria === 'updateProjectAggregation') {
          getProjectById({
            id: this.$route.params.id || this.projectId,
            showModal: false,
          });
          spinner(false);
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    async subscribeOnAcrhivingOfProject() {
      const { detailProjectData: project, userId, $router: router } = this;
      try {
        const { PK, SK: sourceSK } = project ?? {
        };
        const variables = {
          PK: `${PK}:USER#${userId}`,
          sourceSK,
        };
        await onSortingDocument(variables, () => {
          this.dialogForProjectDetail = false;
          router.push({
            name: 'projects',
          });
        });
      } catch (e) {
        console.log('err subscribeSortintDocumentChanging', e);
      }
    },
    clearForm() {
      this.$refs.projectAnalyticsFrom.reset();
      this.baseBudgetForCalc = null;
      if (!this.$route.params.id) {
        this.setDetailDataOfProject(null);
        this.setAggregationData(null);
      }
    },
    async editProject() {
      this.spinner(true);
      const {
        baseBudget,
        notes,
        area,
        phase,
        number,
        projectName,
        powerDensity,
        areaUnit,
      } = this.projectDetailEdit;
      const projectId = this.$route.params.id || this.projectId;
      await this.updateProject({
        id: projectId,
        baseBudget: baseBudget || 0,
        powerDensity: powerDensity || 0,
        area: area || 0,
        phase,
        number,
        projectName,
        areaUnit,
        ...this.renderAddress,
      }, 'updateProjectAggregation');
      await this.updateProject({
        projectId,
        aggregationId: this.id,
        notes,
      }, 'updateProjectAggregationNotes');
      this.dialogForProjectDetail = false;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep textarea {
  overflow: auto !important;
}
.chart {
  padding-top: 50px;
}
::v-deep .bordered-buttons.base-btn {
  .v-btn__content {
    color: var(--v-lightBlack-base) !important;
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
</style>
