<template>
  <div
    v-if="showAIChat"
    data-airops-widget="36faca9b-4fa4-4c98-877f-225e9488abea"
    data-airops-init="true" />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AppAIChat',
  computed: {
    ...mapGetters('FeatureFlags', ['showAIChat']),
  },
  created() {
    if (this.showAIChat) this.loadExternalResources();
  },
  methods: {
    loadExternalResources() {
      // Load the external script file
      const script = document.createElement('script');
      script.src = 'https://assets.airops.com/widget/index.js';
      script.async = true;

      // update css styles after script is loaded
      script.onload = () => {
        this.updateStyles();
      };

      document.head.appendChild(script);
    },
    updateStyles() {
      this.$nextTick();
      const host = document.querySelectorAll('[data-airops-widget="36faca9b-4fa4-4c98-877f-225e9488abea"]')[0];

      // get the shadow element
      const shadowDom = host.shadowRoot;

      const shadowStyle = document.createElement('style');
      shadowStyle.textContent = `
        .ao-h-\\[740px\\] {
          height: 580px;
        }
        .ao-bg-primary, .ao-bg-user-message {
          background: var(--ao-primary);
        }
        .ao-bottom-12 {
          bottom: 34px;
          right: 42px;
          @media only screen and (max-width: 959.98px) {
            bottom: 88px;
            right: 4px;
          }
        }
      `;

      // add custom styles to it
      shadowDom.appendChild(shadowStyle);
    },
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
div[data-airops-widget] {
  --ao-primary: linear-gradient(270deg, #71D6E0 -42.03%, #0AA8DA 134.48%);
  --ao-user-message: linear-gradient(270deg, #71D6E0 -42.03%, #0AA8DA 134.48%);
  z-index: 9;
}
</style>