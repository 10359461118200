<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="70%"
      max-width="800px"
      transition="scale-transition"
      persistent>
      <v-card>
        <v-card-title class="text-h5 darkGrey white--text justify-space-between">
          Getting Started with Sourcery
          <v-icon
            color="white"
            size="30"
            class="pointer"
            @click="closeModal">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="text-center">
          <iframe
            width="100%"
            height="400"
            class="mt-4"
            src="https://gosourcery.wistia.com/medias/unkyyrewyj"
            title="Welcome video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen />
        </v-card-text>
        <v-divider />
        <v-card-actions
          class="justify-center gap-6 py-3">
          <v-btn
            class="uppercase"
            color="mainBlue"
            outlined
            @click="bookMeeting">
            Book a success call
          </v-btn>
          <v-btn
            class="uppercase"
            color="mainBlue"
            @click="closeModal">
            Get Started
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'TourModal',
  data () {
    return {
      dialog: true,
    };
  },
  methods: {
    bookMeeting() {
      window.open('https://meetings.hubspot.com/paul-boken/support-call', '_blank');
      this.closeModal();
    },
    closeModal() {
      this.dialog = false;
      // update the value in store and DB
      this.$store.dispatch('disableNewUser');
    },
  },
};
</script>
<style scoped>
.gap-6 {
  gap: 24px;
}
</style>
