export const getDefaultState = () => {
  return {
    scheduleSessionUuid: null,
    activeCellHeader: '',
    defaultFields: [],
    scheduleId: null,
    schema: [],
    isActiveEditMode: false,
    isDisableKeyboardController: false,
    selectedCells: [],
    copiedContent: null,
    libraryId: null,
    projectPermissions: [],
    cellPointer: null,
    creatorLogo: null,
    creatorLogoKey: null,
    availReferenceFields: [],
    projectTags: [],
    showCommentsModal: false,
    modalMode: '',
    modalRowId: '',
    modalType: 'add',
    rightAlignment: [
      'Budget Price',
      'Total Cost',
      'Budget Price - Project Override',
      'Library Budget Price',
    ],
    readOnlyFields: [],
    hoveredColumn: {
    },
  };
};
export const state = getDefaultState();
