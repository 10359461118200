<template>
  <app-dialog
    v-model="dialog"
    :value.sync="dialog"
    width="637"
    content-class="v-dialog__form">
    <v-card>
      <v-card-title style="z-index: 9999">
        Invite As Workspace Member
        <div class="d-flex align-center">
          <v-icon
            color="#fff"
            class="ml-2"
            @click="dialog = false">
            mdi-close
          </v-icon>
        </div>
      </v-card-title>
      <v-card-text class="pa-6">
        <p class="description medium-p">
          Invite the following users on workspace members:
        </p>
        <v-row
          v-for="(user, index) in users"
          :key="user.id"
          class="mt-6"
          justify="space-between"
          align="center">
          <v-checkbox
            :input-value="isSelectedUser(user.id)"
            :label="user.email"
            hide-details
            @change="updateCheckboxSelection(user.id, $event)" />
          <v-menu
            v-if="isSelectedUser(user.id)"
            offset-y
            nudge-bottom="10"
            content-class="edit-menu">
            <template #activator="{ on }">
              <div
                class="d-flex"
                v-on="on">
                <p class="small-p title-in-modal mb-0 pointer text-capitalize">
                  {{ user.role }}
                </p>
                <span style="width: 20px; height: 20px">
                  <v-icon
                    size="20px"
                    color="mainGrey">
                    mdi-menu-down
                  </v-icon>
                </span>
              </div>
            </template>
            <v-card class="nav-card">
              <template>
                <p
                  v-for="type in permissionTypes"
                  :key="type.value"
                  :class="{'highlighted' : type.value === user.type }"
                  @click="updateUserRole({
                    role: type.value, index
                  })">
                  {{ type.text }}
                </p>
              </template>
            </v-card>
          </v-menu>
        </v-row>
        <p
          v-if="errorLimitMessage"
          class="mt-6 small-p mainRed--text">
          {{ errorLimitMessage }}
        </p>
      </v-card-text>
      <v-card-actions
        class="pt-6 d-flex justify-end">
        <template>
          <v-btn
            outlined
            color="lightBlue"
            @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            :disabled="isDisableDoneButton || !!errorLimitMessage"
            color="lightBlue"
            @click="handleShareWorkspace">
            Done
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </app-dialog>
</template>
<script>
import ErrorsText from '@/constants/errors';
import { GET_PERMISSION_TYPES } from '@/constants/userPermissions';
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
export default {
  name: 'WorkspaceCustomMembersDialog',
  components: {
  },
  props: {
    customMembersDialog: {
      type: Boolean,
      default: false,
    },
    invitedUsers: {
      type: Array,
      default: () => [],
    },
    permissionTypes: {
      type: Array,
      default: () => GET_PERMISSION_TYPES(['viewer', 'editor', 'creator']),
    },
  },
  data() {
    return {
      selectedUsers: [],
      users: [],
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    errorLimitMessage() {
      const {
        usedLicenses,
        premiumLicenses,
        freeLicenses,
        workspaceType,
      } = this.activeWorkspace || {
      };
      const licenses = workspaceType === 'free'
        ? freeLicenses : premiumLicenses;
      const selectedUsersLength = this.selectedUsers?.length || 0;
      const extraUsers = licenses - usedLicenses - selectedUsersLength;
      if (extraUsers < 0) {
        return ErrorsText.SHARE_LIMIT_ERROR(extraUsers, usedLicenses, licenses);
      } return '';
    },
    activeWorkspace() {
      return this.$store.getters['Workspace/getActiveWorkspace'];
    },
    isDisableDoneButton() {
      return Boolean(!this.selectedUsers?.length);
    },
    dialog: {
      get() {
        return this.customMembersDialog;
      },
      set(val) {
        this.$emit('update:customMembersDialog', val);
        this.$emit('update:invitedUsersForShareWorkspace', []);
        this.selectedUsers = [];
      },
    },
  },
  watch: {
    invitedUsers: {
      handler(val) {
        this.users = val.map(user => ({
          ...user,
          role: 'viewer',
        }));
      },
    },
    immediate: true,
  },
  methods: {
    ...mapActions(['handleError']),
    ...mapActions('Workspace', ['inviteToWorkspace']),
    ...mapMutations(['spinner', 'openSnackBar']),
    updateCheckboxSelection(id) {
      if (this.isSelectedUser(id)) {
        this.selectedUsers = this.selectedUsers.filter(userId => userId !== id);
      } else {
        this.selectedUsers = [...this.selectedUsers, id];
      }
    },
    isSelectedUser(id) {
      return this.selectedUsers?.some(userId => userId === id);
    },
    updateUserRole({ role, index }) {
      this.users = this.users.map((user, i) => (
        i === index ? {
          ...user,
          role,
        } : user
      ));
    },
    async handleShareWorkspace() {
      const { selectedUsers, users, inviteToWorkspace } = this;
      const members = selectedUsers.map(id => (
        {
          ...users.find(user => user.id === id),
        }
      ));
      inviteToWorkspace({
        members,
      });
      this.dialog = false;
    },
  },
};
</script>
<style scoped lang="scss">
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
</style>
