<template>
  <fragment>
    <AppNavBarBottom
      v-if="$vuetify.breakpoint.smAndDown" />
    <AppNavBarSide
      v-if="$vuetify.breakpoint.mdAndUp" />
  </fragment>
</template>
<script>
export default {
  name: 'AppNavBar',
  components: {
    AppNavBarSide: () => import('./AppNavBarSide'),
    AppNavBarBottom: () => import('./AppNavBarBottom'),
  },
};
</script>