const NEW_FEATURES_AND_FEEDBACK = 'https://airtable.com/shrJihjdc5LK8od1d';
const BUGS_AND_ISSUES = 'https://airtable.com/shrWYhCnZj1u6FWOM';
const TRAINING_VIDEOS = 'https://www.youtube.com/channel/UCTxPyNyUMzqaOVrPs2iCz1g/videos';
const HELP_CENTER = 'https://sourcery.tawk.help/';
const INFO_EMAIL = 'info@gosourcery.com';
export {
  NEW_FEATURES_AND_FEEDBACK,
  BUGS_AND_ISSUES,
  TRAINING_VIDEOS,
  HELP_CENTER,
  INFO_EMAIL,
};
