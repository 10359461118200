<template>
  <v-dialog
    overlay-opacity="0"
    :value="true"
    persistent
    max-width="450">
    <v-card>
      <v-icon
        class="d-flex ml-auto pr-2 pt-2"
        @click="$emit('resolve', false)">
        mdi-close
      </v-icon>
      <v-card-text class="text-center px-5 pt-1">
        <p v-safe-html="text" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="lightBlue"
          @click="cancel">
          {{ params.cancel || 'Cancel' }}
        </v-btn>
        <v-btn
          color="lightBlue"
          @click="accept">
          {{ params.accept || 'Ok' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    text() {
      return this.params?.text || '';
    },
  },
  methods: {
    cancel() {
      const { cancelResponse } = this.params;
      this.$emit('resolve', cancelResponse ?? false);
    },
    accept() {
      const { acceptResponse } = this.params;
      this.$emit('resolve', acceptResponse ?? true);
    },
  },
};
</script>
<style scoped lang="scss">
p {
  margin: 0;
}
</style>
