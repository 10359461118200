import UserProfileApi from '@/services/graphql/userProfile';
import {
  Auth, Storage,
} from 'aws-amplify';
import { IS_STORE_ROOT } from '@/constants';
export const actions = {
  async getUserProfile(
    { commit, dispatch, rootGetters }) {
    try {
      const { userId: username } = rootGetters;
      const { data } = await UserProfileApi.getUserProfile({
        username,
      });
      dispatch('getCredentials');
      commit('setUserData', data.response);
      dispatch('getOwnLogo');
      dispatch('getUserAvatar');
    } catch (err) {
      console.log('getUserProfile err', err);
    }
  },
  async getCredentials({ commit }) {
    let cred = await Auth.currentUserCredentials();
    commit('setCredentials', cred);
  },
  async updateUserProfile({ dispatch, commit, rootGetters }, variables) {
    try {
      commit('spinner', true, {
        root: true,
      });
      const { userId: username } = rootGetters;
      const { data } = await UserProfileApi.updateUserProfile({
        ...variables,
        username,
      });
      commit('setUserData', data.response);
      dispatch('getOwnLogo');
      dispatch('getUserAvatar');
    } catch (err) {
      dispatch('handleError', err, {
        root: true,
      });
    } finally {
      commit('spinner', false, {
        root: true,
      });
    }
  },
  async getOwnLogo({ commit, state, dispatch, rootGetters }) {
    try {
      const key = state.userData?.logo;
      let url = null;
      if (key) {
        if (rootGetters['FeatureFlags/useIncreaseCaching']) {
          await dispatch('ManageFiles/parseFile', {
            key,
            config: {
              level: 'public',
            },
          }, IS_STORE_ROOT);
          url = rootGetters['ManageFiles/getFileByKey'](key);
        } else {
          url = await Storage.get(key, {
            level: 'public',
          });
        }
        commit('setOwnLogo', url);
      } else {
        commit('setOwnLogo', null);
      }
    } catch (err) {
      dispatch('handleError', err, {
        root: true,
      });
    }
  },
  async getUserAvatar({ commit, dispatch, state, rootGetters }) {
    try {
      const key = state.userData?.picture;
      if (key) {
        let url = null;
        if (rootGetters['FeatureFlags/useIncreaseCaching']) {
          await dispatch('ManageFiles/parseFile', {
            key,
            config: {
              customPrefix: {
                public: 'public/avatars/',
              }, level: 'public',
            },
          }, IS_STORE_ROOT);
          url = rootGetters['ManageFiles/getFileByKey'](key);
        } else {
          url = await Storage.get(key, {
            customPrefix: {
              public: 'public/avatars/',
            }, level: 'public',
          });
        }
        commit('setUserAvatar', url);
        commit('setUserAvatarKey', key);
      } else {
        commit('setUserAvatar', null);
        commit('setUserAvatarKey', null);
      }
    } catch (err) {
      dispatch('handleError', err, {
        root: true,
      });
    }
  },
};
