import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#004488',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#70B800',
        warning: '#FFC107',
        // main colors start
        mainRed: '#C9103A',
        mainYellow: '#E0DE0D',
        mainBlue: '#71AFC4',
        mainGreen: '#71991F',
        mainGrey: '#8CA3A9',
        mainBlack: '#0C0C0C',
        grey: '#F8F8F8',
        darkGrey: '#353135',
        // main colors end
        greyDarken: '#757575',
        lightGrey: '#BAC2C3',
        headerBlue: '#37405B',
        lightBlue: '#71D6E0',
        mainTurquoise: '#6ED3EA',
        redLight: '#EF5350',
        mainOrange: '#E0BF10',
        lightBlack: '#171E37',
        borderGrey: '#E2E5E6',
      },
    },
  },
  breakpoint: {
    thresholds: {
      // xs: 375,
      // sm: 540,
      // md: 899,
      // lg: 1280,
    },
  },
  icons: {
    iconfont: 'md',
  },
});
