<template>
  <div>
    <component
      :is="getComponentName"
      :name="getComponentName"
      v-bind="{...$attrs, ...$props}"
      :value.sync="syncValue"
      @outside="$emit('click:outside')">
      <template
        v-for="(_, name) in $scopedSlots"
        v-slot:[name]="props">
        <slot
          :name="name"
          v-bind="props" />
      </template>
    </component>
  </div>
</template>
<script>
import ProxyDialog from '@/components/App/AppProxyDialog/ProxyDialog';
import RegularDialog from '@/components/App/AppProxyDialog/RegularDialog';
import { mapGetters } from 'vuex';
export default {
  name: 'AppDialog',
  components: {
    RegularDialog,
    ProxyDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('FeatureFlags', ['backButtonToCloseModalWindows']),
    isMobile() {
      return Boolean(this.$mobileDetect.mobile()) || Boolean(this.$mobileDetect.tablet());
    },
    isNeedRegular() {
      return !this.isMobile || this.disabled || !this.backButtonToCloseModalWindows;
    },
    getComponentName() {
      return this.isNeedRegular ? 'RegularDialog' : 'ProxyDialog';
    },
    syncValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
  },
};
</script>
