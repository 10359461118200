const SORTING_MODE_LAST_ACCESSED = 'lastAccessed';
const SORTING_MODE_CREATION_DATE = 'creationDate';
const SORTING_MODE_COLLECTION_NAME_ACS = 'collectionName';
const SORTING_MODE_COLLECTION_NAME_DESC = 'collectionName';

const COLLECTION_SORT_ITEMS = [
  {
    name: 'Creation Date',
    request: 'listMyCollections',
    sortByDate: true,
    sortingMode: SORTING_MODE_CREATION_DATE,
    requestGroup: {
      followed: 'listFollowedCollections',
    },
    resourceType: 'collection',
    dataTest: 'creation_date_dropdown_option',
  },
  {
    name: 'Recently Accessed',
    request: 'listResentlyCollections',
    requestGroup: {
      followed: 'listFollowedCollectionsLastAccessed',
    },
    sortByDate: false,
    sortingMode: SORTING_MODE_LAST_ACCESSED,
    resourceType: 'collection',
    dataTest: 'recently_accessed_dropdown_option',
  },
  {
    name: 'Collection Name',
    request: 'getSortedCollectionNameAcs',
    sortingMode: SORTING_MODE_COLLECTION_NAME_ACS,
    nameInSchema: SORTING_MODE_COLLECTION_NAME_ACS,
    requestGroup: {
      followed: 'listFollowedCollections',
    },
    ascending: true,
    resourceType: 'collection',
    dataTest: 'collection_name_az_dropdown_option',
    arrowIcon: 'mdi-arrow-down',
  },
  {
    name: 'Collection Name',
    request: 'getSortedCollectionNameDesc',
    sortingMode: SORTING_MODE_COLLECTION_NAME_DESC,
    nameInSchema: SORTING_MODE_COLLECTION_NAME_DESC,
    requestGroup: {
      followed: 'listFollowedCollections',
    },
    ascending: false,
    resourceType: 'collection',
    dataTest: 'collection_name_za_dropdown_option',
    arrowIcon: 'mdi-arrow-up',
  },
];
const SORTING_MODE_PROJECT_NAME = 'projectName';
const SORTING_MODE_PROJECT_NUMBER = 'projectNumber';
const SORT_ALPHABETICAL_FIELDS = [SORTING_MODE_PROJECT_NAME, SORTING_MODE_PROJECT_NUMBER];
const PROJECT_SORT_ITEMS = [
  {
    name: 'Creation Date',
    request: 'getUserProjectsByStatus',
    sortingMode: SORTING_MODE_CREATION_DATE,
    resourceType: 'project',
    dataTest: 'creation_date_dropdown_option',
  },
  {
    name: 'Recently Accessed',
    request: 'getSortedUserProjectsByLastAccess',
    sortingMode: SORTING_MODE_LAST_ACCESSED,
    resourceType: 'project',
    dataTest: 'recently_accessed_dropdown_option',
  },
  {
    name: 'Project Name',
    request: 'getUserProjectsByProjectNameAz',
    sortingMode: SORTING_MODE_PROJECT_NAME,
    nameInSchema: SORTING_MODE_PROJECT_NAME,
    ascending: true,
    resourceType: 'project',
    dataTest: 'project_name_az_dropdown_option',
    arrowIcon: 'mdi-arrow-down',
  },
  {
    name: 'Project Name',
    request: 'getUserProjectsByProjectNameZa',
    sortingMode: SORTING_MODE_PROJECT_NAME,
    nameInSchema: SORTING_MODE_PROJECT_NAME,
    ascending: false,
    resourceType: 'project',
    dataTest: 'project_name_za_dropdown_option',
    arrowIcon: 'mdi-arrow-up',
  },
  {
    name: 'Project Number',
    request: 'getSortedUserProjectsByProjectNumberAz',
    sortingMode: SORTING_MODE_PROJECT_NUMBER,
    nameInSchema: 'number',
    ascending: true,
    resourceType: 'project',
    dataTest: 'project_number_az_dropdown_option',
    arrowIcon: 'mdi-arrow-down',
  },
  {
    name: 'Project Number',
    request: 'getSortedUserProjectsByProjectNumberZa',
    sortingMode: SORTING_MODE_PROJECT_NUMBER,
    nameInSchema: 'number',
    ascending: false,
    resourceType: 'project',
    dataTest: 'project_number_za_dropdown_option',
    arrowIcon: 'mdi-arrow-up',
  },
];
export {
  COLLECTION_SORT_ITEMS,
  PROJECT_SORT_ITEMS,
  SORTING_MODE_LAST_ACCESSED,
  SORTING_MODE_CREATION_DATE,
  SORT_ALPHABETICAL_FIELDS,
  SORTING_MODE_COLLECTION_NAME_ACS,
  SORTING_MODE_COLLECTION_NAME_DESC,
};
