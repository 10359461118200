import RowCommentsFollowers from '@/services/graphql/rowCommentsFollowers';
import Subscriptions from '@/services/graphql/subscriptions';
import CommentsApi from '@/services/graphql/comments';
import { IS_STORE_ROOT } from '@/constants';
import router from '@/router';
import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';
import { uniqBy } from 'lodash';
import {
  COMMENT_RESOURCE_TYPE,
  COMMENT_STATUS,
  COMMENTS_RESOURCES,
} from '@/constants/comments';
import {
  subscribeForComments, subscribeForTotalUnreadCounter,
  subscribeSlantDocument, subscribeSlantDocumentsList,
} from '@/services/graphql/commentsSubscriptions';
export const actions = {
  async subscribeRowComment(
    { commit, dispatch, rootGetters },
    {
      projectId,
      rowId,
      criteria,
      action,
    },
  ) {
    const requestType = ['subscribeCollaboratorChange', 'subscribeRowFollowerChange']
      .includes(criteria) ?
      criteria : 'subscribeRowComment';
    try {
      const { userId: recipientId } = rootGetters;
      const rowComment = await Subscriptions[requestType]({
        projectId,
        rowId,
        ...(['subscribeCreateRowCommentPrivate', 'subscribePutCommentThumbnailPrivate']
          .includes(criteria) && {
          recipientId,
        }),
      }, criteria).subscribe({
        next: ({ value }) => {
          dispatch(action, value.data.response);
        },
      });
      commit('addCommentsSubscriptions', rowComment, {
        root: true,
      });
    } catch (err) {
      console.log('subs err', err);
    }
  },
  async subscribeProjectComment(
    { commit, dispatch, rootGetters },
    {
      projectId,
      PK,
      criteria,
      action,
    },
  ) {
    try {
      const { userId: recipientId } = rootGetters;
      const rowComment = await Subscriptions['subscribeProjectComment']({
        projectId,
        PK,
        ...(criteria === 'subscribeCreateProjectCommentPrivate' && {
          recipientId,
        }),
      }, criteria).subscribe({
        next: ({ value }) => {
          dispatch(action, value.data.response);
        },
      });
      commit('addCommentsSubscriptions', rowComment, {
        root: true,
      });
    } catch (err) {
      console.log('subs err', err);
    }
  },
  deleteComment({ commit, state }, { id } = {
  }) {
    const newComments = state.commentsList.filter(comment => comment.id !== id);
    commit('setComments', newComments);
  },
  deleteCommentV2({ commit, state }, { commentId, resourceType } = {
  }) {
    const currentResource = COMMENTS_RESOURCES[resourceType];
    const newComments = state[currentResource.list].filter(comment => comment.commentId !== commentId);
    commit(currentResource.setMutation, newComments);
  },
  setComment({ commit, state, rootState }, payload) {
    const { commentsList, rowFollowers } = state;
    const { cellPointer } = rootState.ProjectDetailsTableSchedule;
    if (!cellPointer || payload.cellPointer === cellPointer) {
      let newComments = [];
      if (payload.mentioned) {
        const newFollowers = uniqBy([...rowFollowers, ...payload.mentioned], 'id');
        commit('setRowFollowers', newFollowers);
      }
      if (commentsList.every(comment => comment.id !== payload.id)) {
        commit('setNewCommentId', payload.id);
        newComments = [...commentsList, payload];
      } else {
        newComments = commentsList.map(comment => (
          comment.id === payload.id ? {
            ...payload,
            creator: comment.creator,
          } : comment
        ));
      }
      commit('setComments', newComments);
    }
  },
  /**
   * new refactored action
   * @param commit
   * @param rootState
   * @param payload
   * @param state
   */
  setCommentV2({ commit, state, dispatch }, payload) {
    try {
      const currentResource = COMMENTS_RESOURCES[payload.resourceType];
      const { setMutation, mutationCollaborators, mutationNewComment } = currentResource;
      const [commentsListV2, collaboratorsListV2] = [state[currentResource.list], state[currentResource.collaboratorsList]];
      if (payload.mentioned) {
        const newCollaborators = uniqBy([...collaboratorsListV2, ...payload.mentioned], 'id');
        commit(mutationCollaborators, newCollaborators);
      }
      let newComments = [];
      if (commentsListV2.every(comment => comment.commentId !== payload.commentId)) {
        commit(mutationNewComment, payload.commentId);
        newComments = [...commentsListV2, payload];
      } else {
        newComments = commentsListV2.map(comment => (
          comment.commentId === payload.commentId ? {
            ...payload,
            creator: comment.creator,
          } : comment
        ));
      }
      commit(setMutation, newComments);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  setCommentImage({ commit, state }, { commentId, attachment, resourceType }) {
    const currentResource = COMMENTS_RESOURCES[resourceType];
    const commentsListV2 = state[currentResource.list];
    const newComments = commentsListV2.map(comment => (
      comment.commentId === commentId ? {
        ...comment,
        attachments: comment.attachments.map(att => att.id == attachment.id ? attachment : att),
      } : comment
    ));
    commit(currentResource.setMutation, newComments);
  },
  async getRowFollowers({ commit, rootState }, variables) {
    commit('setRowFollowers', []);
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await RowCommentsFollowers.getRowFollowers({
        ...variables,
        workspaceId,
      });
      const { response: followers = [] } = data ?? {
      };
      if (!followers) {
        commit('setRowFollowers', []);
        return;
      }
      const filteredFollowers = followers.filter(item => item !== null);
      commit('setRowFollowers', filteredFollowers);
    } catch (err) {
      console.log('getRowFollowers err', err);
    }
  },
  manageFollowers({ commit }, payload) {
    commit('setRowFollowers', payload.usersData);
  },
  async subscribeCellCommentAmountChanging(
    { commit, rootGetters }, variables) {
    try {
      const { userId: username } = rootGetters;
      const subscribtion = await Subscriptions.subscribeCellCommentAmountChanging({
        ...variables,
        username,
      }).subscribe({
        next: ({ value }) => {
          commit('setCommentCellPointers', value.data.response?.cellPointers);
        },
      });
      commit('addCommentsSubscriptions', subscribtion, {
        root: true,
      });
    } catch (err) {
      console.log('subscribeCellCommentAmountChanging err', err);
    }
  },
  async getCommentCellPointers({ commit, rootState }, variables) {
    commit('setCommentCellPointers', []);
    try {
      const { id: projectId } = router.currentRoute.params;
      const workspaceId = rootState.Workspace.activeWorkspaceId;
      const { data } = await CommentsApi.getCommentCellPointers({
        ...variables,
        projectId,
        workspaceId,
      });
      commit('setCommentCellPointers', data.response?.cellPointers || []);
    } catch (err) {
      console.log(err);
    }
  },
  async getSlants({ commit, rootState, state, rootGetters }, variables) {
    try {
      const { scheduleId: tableId } = rootState.ProjectDetailsTableSchedule;
      const currentVersion = rootGetters['ProjectVersions/getCurrentVersion'];
      const versionId = currentVersion === DEFAULT_PROJECT_VERSION ? undefined : currentVersion;
      const { id: projectId } = router.currentRoute.params;
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await CommentsApi.getSlants({
        ...variables,
        projectId,
        tableId,
        tableType: 'schedule',
        workspaceId,
        versionId,
      });
      const { slants } = state;
      const newSlants = [...slants, ...(data.response || [])];
      const uniqSlants = uniqBy(newSlants, 'id');
      commit('setSlants', uniqSlants);
    } catch (err) {
      console.log('errGetSlants', err);
    }
  },
  async getSlantsV2({ rootState, dispatch }, { payload, resourceType, resourceId }) {
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      let rowsIds = [];
      if (payload) {
        rowsIds = {
          ...payload,
        };
      }
      const { data } = await CommentsApi.listSlants({
        resourceType,
        resourceId,
        workspaceId,
        rowsIds,
      });
      dispatch('setResourceSlants', {
        data,
        resourceType,
      });
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  setResourceSlants({ commit, state }, { data, resourceType }) {
    let { response = [] } = data ?? {
    };
    // In case if we create resource back returns us not array instance
    if (!Array.isArray(response)) {
      response = [response];
    }
    const currentResource = COMMENTS_RESOURCES[resourceType];
    const [slants = [], mutationSlants] = [
      state[currentResource.slantsList],
      currentResource.slantsMutation,
    ];
    const newSlants = [...slants, ...response];
    const uniqSlants = uniqBy(newSlants, 'id');
    commit(mutationSlants, uniqSlants);
  },
  async executeGetSlants({ dispatch, state, rootGetters }, payload) {
    const { slants } = state;
    const viewInfoLength = rootGetters['ScheduleRows/viewInfoLength'];
    if (payload?.length > viewInfoLength) {
      const rowsIds = payload.reduce((result, { id }) => {
        if (slants.every(slant => slant.rowId !== id)) {
          return [...result, id];
        }
        return result;
      }, []);
      await dispatch('getSlants', {
        rowsIds,
      });
    }
  },
  async executeGetSlantsV2({ dispatch, state }, { payload, resourceType, resourceId, viewInfoLength }) {
    try {
      const currentResource = COMMENTS_RESOURCES[resourceType];
      const slants = state[currentResource.slantsList];
      let rowsIds = [];
      if (payload?.length > viewInfoLength) {
        rowsIds = payload.reduce((result, { id }) => {
          if (slants.every(slant => slant.rowId !== id)) {
            return [...result, id];
          }
          return result;
        }, []);
      }
      await dispatch('getSlantsV2', {
        rowsIds,
        resourceType,
        resourceId,
      });
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async setSlant({ dispatch, rootState, commit }, variables) {
    try {
      commit('spinner', true, IS_STORE_ROOT);
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await CommentsApi.setSlant({
        ...variables,
        workspaceId,
      });
      dispatch('manageSlant', data.response);
    } catch (err) {
      console.log('errGetSlants', err);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async setSlantV2({ dispatch, rootState }, variables) {
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await CommentsApi.setUserSlant({
        ...variables,
        workspaceId,
      });
      dispatch('manageSlantV2', {
        newSlant: data.response,
        resourceType: variables.resourceType,
      });
    } catch (err) {
      console.log('errGetSlants', err);
    }
  },
  manageSlantV2({ commit, state }, { newSlant, resourceType }) {
    const currentResource = COMMENTS_RESOURCES[resourceType];
    const [slants, mutationSlants] = [state[currentResource.slantsList], currentResource.slantsMutation];
    let newSlants;
    if (slants.every(item => item.rowId !== newSlant.rowId)) {
      newSlants = [...slants, newSlant];
    } else {
      newSlants = slants.map(slant => (
        slant.rowId === newSlant.rowId ? {
          ...newSlant,
        } : slant
      ));
    }
    commit(mutationSlants, newSlants);
  },
  manageSlant({ commit, state, rootState }, newSlant) {
    const { slants } = state;
    const { viewInfo } = rootState.ScheduleRows;
    let newSlants = [];
    const newViewInfo = viewInfo.map(rowItem => (
      rowItem.id === newSlant.rowId ? {
        ...rowItem,
        commentsAmount: newSlant.commentsAmount,
      } : rowItem
    ));
    if (slants.every(item => item.rowId !== newSlant.rowId)) {
      newSlants = [...slants, newSlant];
    } else {
      newSlants = slants.map(slant => (
        slant.rowId === newSlant.rowId ? {
          ...newSlant,
        } : slant
      ));
    }
    commit('ScheduleRows/setViewInfo', newViewInfo, {
      root: true,
    });
    commit('setSlants', newSlants);
  },
  async subscribeSlantDocument({ dispatch, rootState }, { resourceType, resourceId }) {
    try {
      const workspaceId = rootState.Workspace.activeWorkspaceId;
      const subscription = await subscribeSlantDocument({
        workspaceId,
        resourceType,
        resourceId,
      }, ({ data }) => {
        dispatch('manageSlantV2', {
          newSlant: data.response,
          resourceType,
        });
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: `${resourceType}_${resourceId}_slant`,
      }, IS_STORE_ROOT);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async subscribeSlantDocumentsList({ dispatch, rootState }, { resourceType, resourceId }) {
    try {
      const workspaceId = rootState.Workspace.activeWorkspaceId;
      const subscription = await subscribeSlantDocumentsList({
        workspaceId,
        resourceType,
        resourceId,
      }, ({ data }) => {
        dispatch('setResourceSlants', {
          data,
          resourceType,
        });
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: `${resourceType}_${resourceId}_slant-list`,
      }, IS_STORE_ROOT);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async subscribeCommentsAmount(
    { commit, rootGetters, dispatch, rootState }, criteria = 'subscribeSetSlant'
  ) {
    let projectId = undefined;
    if (criteria !== 'subscribeTotalUnreadComments') {
      projectId = router.currentRoute.params.id;
    }
    const { userId } = rootGetters;
    let PK = `USER#${userId}`;
    if (criteria == 'subscribeTotalUnreadComments') {
      PK = `WORKSPACE#${rootState.Workspace.activeWorkspaceId}:USER#${userId}`;
    }
    try {
      const subscription = await Subscriptions[criteria]({
        projectId,
        PK,
      }).subscribe({
        next: ({ value }) => {
          if (criteria !== 'subscribeTotalUnreadComments') {
            dispatch('manageSlant', value.data.response);
          } else {
            dispatch('Projects/setProjectUnreadComments', value.data.response, IS_STORE_ROOT); //only last update
          }
        },
      });
      if (criteria !== 'subscribeTotalUnreadComments') {
        commit('addTableSubscriptions', subscription, {
          root: true,
        });
      } else {
        commit('addTotalUnreadCommentsSubscription', subscription, {
          root: true,
        });
      }
    } catch (err) {
      console.log(`err ${criteria}`, err);
    }
  },
  readAllCommentsSlant({ state, commit }) {
    const newSlants = state.slants.map(e => ({
      ...e,
      commentsUnread: 0,
      unreadMessageIDs: [],
    }));
    commit('setSlants', newSlants);
  },
  async toggleResolvedComments({ commit, dispatch, rootState }, payload) {
    try {
      commit('spinner', true, IS_STORE_ROOT);
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const projectId = router.currentRoute.params.id;
      await CommentsApi.toggleResolvedComments({
        hideResolved: payload,
        projectId,
        workspaceId,
      });
      commit('setProjectUserHideResolvedToggle', payload);
    } catch (error) {
      dispatch('handleError', error, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async toggleResolvedCommentsV2({ commit, dispatch, rootState }, {
    resourceType,
    resourceId,
    hideResolved,
  }) {
    try {
      commit('spinner', true, IS_STORE_ROOT);
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      await CommentsApi.toggleResolvedResourceComments({
        hideResolved,
        resourceType,
        resourceId,
        workspaceId,
      });
      const mutationName = resourceType == COMMENT_RESOURCE_TYPE.PROJECT ? 'setProjectUserHideResolvedToggleV2' : 'setCollectionUserHideResolvedToggleV2';
      commit(mutationName, hideResolved);
    } catch (error) {
      dispatch('handleError', error, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async subscribeCommentsV2({ dispatch, rootState }, { resourceId, resourceType, commentKind }) {
    try {
      const workspaceId = rootState.Workspace.activeWorkspaceId;
      const subscription = await subscribeForComments({
        workspaceId,
        resourceType,
        resourceId,
        commentKind,
      }, ({ data }) => {
        const { status, commentId } = data.response;
        if (status == COMMENT_STATUS.DELETED) {
          dispatch('deleteCommentV2', {
            commentId,
            resourceType,
          });
          return;
        }
        dispatch('setCommentV2', data.response);
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: `${resourceType}_${resourceId}`,
      }, IS_STORE_ROOT);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async subscribeTotalUnreadCounter({ dispatch, rootState }, {
    resourceType,
    libraryId,
    useLibraryIdInTitle = false,
  }) {
    try {
      const workspaceId = rootState.Workspace.activeWorkspaceId;
      const subscription = await subscribeForTotalUnreadCounter({
        workspaceId,
        resourceType,
        libraryId,
      }, ({ data }) => {
        dispatch('Collections/updateUnreadComments', {
          updatedCollectionId: data.response.id,
          commentsUnread: data.response.commentsUnread,
        }, IS_STORE_ROOT);
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: `${resourceType}${useLibraryIdInTitle ? '_'.concat(libraryId) : ''}_unread_counter`,
      }, IS_STORE_ROOT);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async setReadAllResourceComments({ dispatch, rootState }, { resourceType, resourceId }) {
    try {
      const workspaceId = rootState.Workspace.activeWorkspaceId;
      const { data } = await CommentsApi.readAllResourceComments({
        workspaceId,
        resourceType,
        resourceId,
      });
      dispatch('readAllCommentsSlantV2', {
        newSlant: data.response,
        resourceType,
      });
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  readAllCommentsSlantV2({ commit, state }, { resourceType }) {
    const currentResource = COMMENTS_RESOURCES[resourceType];
    const [slants, mutationSlants] = [state[currentResource.slantsList], currentResource.slantsMutation];
    const newSlants = slants.map(e => ({
      ...e,
      commentsUnread: 0,
      unreadMessageIDs: [],
    }));
    commit(mutationSlants, newSlants);
  },
};
