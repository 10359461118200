import ProjectVersionsApi from '@/services/graphql/projectVersions';
import router from '@/router';
export const actions = {
  async getListProjectVersions({ commit, rootState }, { projectId }) {
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await ProjectVersionsApi.listProjectVersions({
        projectId,
        workspaceId,
      });
      commit('setListOfVersions', data.response.data);
      const { query } = router.currentRoute;
      const queryCurrentVersionId = query?.version;
      commit('setCurrentVersion', queryCurrentVersionId);
    } catch (err) {
      console.log(err);
    }
  },
};
