<template>
  <v-row
    v-show="showSpinner || uploadingSpinner"
    justify="center"
    align="center"
    class="spinner-container">
    <!-- <v-progress-circular
      :size="50"
      indeterminate
      color="mainRed" /> -->
    <div
      class="d-flex flex-column"
      style="z-index: 10000000000">
      <img
        src="@/assets/icons/circle-loader.gif">
    </div>
  </v-row>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'AppLoadingSpinner',
  computed: {
    ...mapState(['showSpinner', 'uploadingSpinner']),
  },
};
</script>

