import { getDefaultState } from '@/store/Libraries/state';
export const mutations = {
  setLibrariesList(state, payload) {
    state.librariesList = payload;
  },
  setIsFetchingLibraries(state, payload) {
    state.isFetchingLibraries = payload;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};
