//variables for subscribes
const COLLAPSE_REORDER_SUBSCRIPTION = 'COLLAPSE_REORDER_SUBSCRIPTION';
const PROJECT_PERMISSION = 'PROJECT_PERMISSION';
const COLLECTION_PERMISSION = 'COLLECTION_PERMISSION';
const COLLECTION_ACCESS = 'COLLECTION_ACCESS';
const WORKSPACE_PERMISSION = 'WORKSPACE_PERMISSION';
const NOTIFY_PERMISSION = 'NOTIFY_PERMISSION';
const RECALCULATE_TAG_TOTAL_COST = 'subscribeRecalculateTagTotalCost';
const ON_PRODUCT_UPDATE = 'subscribeOnProductUpdate';
const SEARCH_PROJECT_CELLS_RESULT = 'subscribeSearchProjectCellsResult';
const ASYNC_SEARCH_RESULT = 'ASYNC_SEARCH_RESULT';
const ASYNC_SEARCH_WORKSPACE_PAGES = 'ASYNC_SEARCH_WORKSPACE_PAGES';
//end
const UPGRADE_TO_PREMIUM = 'Please upgrade to use premium features';
const NOT_PERMITTED = `Your assigned role is not permitted to use this feature or you need to upgrade to a paid subscription. \n
If you are a paid user, please contact the collection or project owner to upgrade permissions`;
const UPGRADE_TO_USE = 'Please upgrade to use this function';
const NOT_PERMITTED_ACCESS = `Your assigned role is not permitted to use this feature, \n please contact the collection or project owner to upgrade permissions`;
const NOT_PERMITTED_GRADE = 'Please upgrade to use this feature';
const NOT_PERMITTED_ARCHIVE_PROJECT = 'Project is archived and cannot be updated';
const NOT_PERMITTED_SUGGEST_FOLLOWERS = 'Collection is locked to workspace and cannot be suggested to new followers';
const NOT_PERMITTED_FOLLOW_COLLECTION = 'Collection is locked to workspace and cannot be followed into other workspaces';
const NOT_PERMITTED_SOURCE_LIBRARY = 'This project is locked to a source library';
const TEXT_COPY_CREATED_PERSONAL_WS = 'Personal Workspace is being phased out - Please create new custom workspace';
const TYPE_CREATOR = 'creator';
const TYPE_CREATOR_ADMIN = 'workspaceCreatorAdmin';
const NAME_CREATOR_ADMIN = 'Creator(Admin)';
const TYPE_EDITOR = 'editor';
const TYPE_COLLABORATOR = 'collaborator';
const TYPE_VIEWER = 'viewer';
const TYPE_MEMBER = 'member';
const TYPE_GUEST = 'guest';
const TYPE_FOLLOWER = 'follower';
const TYPE_OWNER = 'owner';
const GRADE_REASON = 'grade';
const NOT_A_MEMBER_OF_COLLECTION = 'You aren\'t a member of this collection';
const ACCESS_COPY_CRATED_PERSONAL_WS = 'disabled copy/created in personal ws';
const ACCESS_REASON = 'access';
const FOLLOWED_PRODUCT_REASON = 'followed product';
const NOT_PERMITTED_FOLLOWED_PRODUCT = 'This is a followed product, you do not have permission to update';
const FOLLOWED_COLLECTION_PRODUCT_REASON = 9;
const NOT_PERMITTED_PRODUCT_FOLLOWED_COLLECTION = 'This product is from a followed collection, you do not have permission to update';
const NO_PERMISSION_FOR_PRODUCT_IN_COLLECTION_REASON = 10;
const NOT_PERMITTED_TO_EDIT_PRODUCT_IN_COLLECTION = 'This product is from a collection you do not have permission to update';
const NOT_PERMITTED_TO_EDIT_FOLLOW_PRODUCT_IN_COLLECTION = 'This product is in a followed collection, to edit first follow product into a workspace collection';
const FOLLOW_EDIT_REASON = 'follow_edit';
const ALLOW_PAGES_REASON = 'pages';
const NOT_PERMITTED_TO_PUBLISH = 'This workspace does not allow to create pages.';
const ARCHIVED_PROJECT_REASON = 'archived project';
const LOCKED_TO_WORKSPACE_FOLLOW_REASON = 'locked to workspace follow';
const LOCKED_TO_WORKSPACE_SUGGEST_REASON = 'locked to workspace suggest';
const NOT_PERMITTED_CREATE_EMBED = 'Collection is locked to workspace and cannot have embed code';
const LOCKED_TO_WORKSPACE_EMBED = 'locked to workspace embed';
const PRODUCT_IS_IN_FOLLOWED_COLLECTION_RESON = 'product_is_in_followed_collection';
const PRODUCT_IS_IN_FOLLOWED_COLLECTION = 'This product is in followed collection, to edit first follow product into a workspace collection';
const ACCESS_MAPPING = {
  [ACCESS_REASON]: NOT_PERMITTED_ACCESS,
  [GRADE_REASON]: NOT_PERMITTED_GRADE,
  [FOLLOW_EDIT_REASON]: NOT_PERMITTED_TO_EDIT_FOLLOW_PRODUCT_IN_COLLECTION,
  [ARCHIVED_PROJECT_REASON]: NOT_PERMITTED_ARCHIVE_PROJECT,
  [LOCKED_TO_WORKSPACE_SUGGEST_REASON]: NOT_PERMITTED_SUGGEST_FOLLOWERS,
  [LOCKED_TO_WORKSPACE_FOLLOW_REASON]: NOT_PERMITTED_FOLLOW_COLLECTION,
  [ACCESS_COPY_CRATED_PERSONAL_WS]: TEXT_COPY_CREATED_PERSONAL_WS,
  [ALLOW_PAGES_REASON]: NOT_PERMITTED_TO_PUBLISH,
  [FOLLOWED_PRODUCT_REASON]: NOT_PERMITTED_FOLLOWED_PRODUCT,
  [FOLLOWED_COLLECTION_PRODUCT_REASON]: NOT_PERMITTED_PRODUCT_FOLLOWED_COLLECTION,
  [NO_PERMISSION_FOR_PRODUCT_IN_COLLECTION_REASON]: NOT_PERMITTED_TO_EDIT_PRODUCT_IN_COLLECTION,
  [PRODUCT_IS_IN_FOLLOWED_COLLECTION_RESON]: PRODUCT_IS_IN_FOLLOWED_COLLECTION,
};
const GET_TEXT_REASON = (reason) => {
  return ACCESS_MAPPING[reason] || NOT_PERMITTED_ACCESS;
};
const SHARED_PERMISSIONS_LEVEL = {
  [TYPE_GUEST]: 1,
  [TYPE_FOLLOWER]: 2,
  [TYPE_VIEWER]: 3,
  [TYPE_COLLABORATOR]: 4,
  [TYPE_EDITOR]: 5,
  [TYPE_CREATOR]: 6,
  [TYPE_CREATOR_ADMIN]: 7,
  [TYPE_OWNER]: 8,
};
const PERMISSION_DESCRIPTION = [
  {
    value: 'viewer', text: 'Viewer',
    message: 'Members with this role can only view and comment on the project. This role is ideal for \
    individuals you wish to limit project access to significantly.',
  },
  {
    value: 'editor', text: 'Editor',
    message: 'Editors can modify project data, making this role suitable for external users whom you\'d \
    like to have specific editing capabilities. These users can view, comment, and edit, but they still have restricted features.',
  },
  {
    value: 'creator', text: 'Creator',
    message: 'This is the highest level of access with unrestricted visibility and editing rights. \
    Creators can modify project setup data and delete projects. This role is intended for trusted internal team members.',
  },
  {
    value: 'creator(admin)', text: 'Creator(Admin)',
    message: 'This is the highest level of access with unrestricted visibility and editing rights. \
    Creators can modify project setup data and delete projects. This role is intended for trusted \
    internal team members. They have access to manage workspace guests, members payment and admin settings',
  },
];
const GET_PERMISSION_TYPES = (roles = []) => {
  return (roles.length === 0)
    ? PERMISSION_DESCRIPTION
    : PERMISSION_DESCRIPTION.filter(({ value }) => roles.includes(value));
};
const GET_USER_ROLES = () => PERMISSION_DESCRIPTION.map(({ text }) => text);
export {
  NAME_CREATOR_ADMIN,
  TYPE_CREATOR_ADMIN,
  PROJECT_PERMISSION,
  COLLECTION_PERMISSION,
  UPGRADE_TO_PREMIUM,
  UPGRADE_TO_USE,
  TYPE_CREATOR,
  TYPE_EDITOR,
  TYPE_COLLABORATOR,
  TYPE_VIEWER,
  SHARED_PERMISSIONS_LEVEL,
  TYPE_MEMBER,
  TYPE_GUEST,
  TYPE_FOLLOWER,
  TYPE_OWNER,
  WORKSPACE_PERMISSION,
  NOTIFY_PERMISSION,
  NOT_PERMITTED,
  ACCESS_REASON,
  GRADE_REASON,
  GET_TEXT_REASON,
  NOT_PERMITTED_ACCESS,
  NOT_PERMITTED_GRADE,
  NOT_PERMITTED_SUGGEST_FOLLOWERS,
  NOT_PERMITTED_ARCHIVE_PROJECT,
  NOT_PERMITTED_FOLLOW_COLLECTION,
  ARCHIVED_PROJECT_REASON,
  LOCKED_TO_WORKSPACE_SUGGEST_REASON,
  LOCKED_TO_WORKSPACE_FOLLOW_REASON,
  ACCESS_MAPPING,
  COLLAPSE_REORDER_SUBSCRIPTION,
  ACCESS_COPY_CRATED_PERSONAL_WS,
  TEXT_COPY_CREATED_PERSONAL_WS,
  RECALCULATE_TAG_TOTAL_COST,
  ON_PRODUCT_UPDATE,
  COLLECTION_ACCESS,
  SEARCH_PROJECT_CELLS_RESULT,
  NOT_PERMITTED_SOURCE_LIBRARY,
  NOT_A_MEMBER_OF_COLLECTION,
  LOCKED_TO_WORKSPACE_EMBED,
  NOT_PERMITTED_CREATE_EMBED,
  NOT_PERMITTED_TO_PUBLISH,
  ALLOW_PAGES_REASON,
  FOLLOWED_PRODUCT_REASON,
  NOT_PERMITTED_FOLLOWED_PRODUCT,
  ASYNC_SEARCH_RESULT,
  ASYNC_SEARCH_WORKSPACE_PAGES,
  FOLLOWED_COLLECTION_PRODUCT_REASON,
  NOT_PERMITTED_PRODUCT_FOLLOWED_COLLECTION,
  NO_PERMISSION_FOR_PRODUCT_IN_COLLECTION_REASON,
  NOT_PERMITTED_TO_EDIT_PRODUCT_IN_COLLECTION,
  FOLLOW_EDIT_REASON,
  NOT_PERMITTED_TO_EDIT_FOLLOW_PRODUCT_IN_COLLECTION,
  PRODUCT_IS_IN_FOLLOWED_COLLECTION_RESON,
  PERMISSION_DESCRIPTION,
  GET_PERMISSION_TYPES,
  GET_USER_ROLES,
};
