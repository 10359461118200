<template>
  <app-dialog
    v-model="dialog"
    :value.sync="dialog"
    width="637"
    persistent
    content-class="v-dialog__form"
    @keydown.esc="dialog = false ">
    <v-card>
      <v-card-title>
        <div class="d-flex align-center">
          <ProjectIcon color="#ffffff" />
          New Project
        </div>
        <v-icon
          color="#fff"
          @click="dialog = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form ref="createProjectForm">
          <v-text-field
            v-model="createProjectData.projectName"
            placeholder="Project Name"
            single-line
            filled
            dense
            :error-messages="errorsMessages('projectName')"
            :append-icon="!!errorsMessages('projectName').length? 'mdi-alert-circle-outline' : ''"
            autocomplete="off" />
          <v-text-field
            v-model="createProjectData.phase"
            placeholder="Project Phase"
            class="mt-6"
            single-line
            filled
            dense
            autocomplete="off" />
          <v-text-field
            v-model="createProjectData.number"
            placeholder="Project Number"
            class="mt-6"
            single-line
            filled
            dense
            autocomplete="off" />
          <location-field
            v-if="dialog"
            class="mt-6"
            single-line
            filled
            dense
            :location-types="[TYPE_GEOCODE]"
            :current-location="renderAddressText"
            @setLocationData="createProjectData.location = $event"
            @setAddressData="createProjectData.address = $event" />
          <v-text-field
            v-model.number="createProjectData.baseBudget"
            placeholder="Base budget"
            class="mt-6"
            type="number"
            single-line
            filled
            dense
            autocomplete="off"
            @keypress="isNumber" />
          <div class="d-flex">
            <v-text-field
              v-model.number="createProjectData.area"
              placeholder="Project area"
              class="mt-6"
              type="text"
              single-line
              filled
              dense
              autocomplete="off"
              @keypress="isNumber" />
            <ProjectsArea
              :radio-model="createProjectData.areaUnit"
              @updateRadioModel="createProjectData.areaUnit = $event" />
          </div>
          <v-text-field
            v-model.number="createProjectData.powerDensity"
            :placeholder="`Target Project Power Density (W/${areaUnitTitle })`"
            class="mt-6"
            type="number"
            single-line
            filled
            dense
            autocomplete="off" />
          <div class="d-flex align-items-center">
            <v-tooltip
              content-class="btn-tooltip"
              :disabled="canCreateProjectWithSourceLibrary(getActiveWorkspaceRole).allowed"
              bottom
              nudge-top="20px">
              <template #activator="{on, attrs}">
                <div
                  class="d-inline-block"
                  v-on="on">
                  <v-checkbox
                    v-model="showLibraryField"
                    v-bind="attrs"
                    :disabled="!canCreateProjectWithSourceLibrary(getActiveWorkspaceRole).allowed"
                    label="Limit product reference search to one library"
                    v-on="on" />
                </div>
              </template>
              <span>{{
                GET_TEXT_REASON(canCreateProjectWithSourceLibrary(getActiveWorkspaceRole).reason)
              }}</span>
            </v-tooltip>
            <div class="d-flex">
              <v-menu
                open-on-hover
                top
                offset-y
                nudge-bottom="25"
                :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="ml-2"
                    small
                    v-on="on">
                    mdi-information
                  </v-icon>
                </template>
                <v-card class="py-2">
                  <p
                    class="text-center mb-1 px-2 p-add-info">
                    After you apply this option product reference will work only for <br> the
                    products of the chosen library.
                  </p>
                  <p
                    class="text-center mb-0 p-add-info">
                    No one will be able to use their own products from other <br> libraries or
                    collections within this project.
                  </p>
                </v-card>
              </v-menu>
            </div>
          </div>
          <v-autocomplete
            v-if="showLibraryField"
            v-model="createProjectData.sourceLibraryId"
            class="project__select"
            :items="getCustomLibraries"
            item-text="name"
            item-value="id"
            label="Choose Library" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="lightBlue"
          class="create-button"
          @click="createProject">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </app-dialog>
</template>
<script>
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ProjectsApi from '@/services/graphql/projects';
import Validations from '@/utils/validations';
import { getAreaUnitTitleByValue } from '@/utils';
import { GET_TEXT_REASON } from '@/constants/userPermissions';
import ErrorsText from '@/constants/errors';
import ProjectsArea from '@/components/Projects/ProjectsArea';
import AppGoogleLocationField from '@/components/App/AppFields/AppGoogleLocationField';
import { TYPE_GEOCODE } from '@/constants/maps';
import { omit } from 'lodash';
export default {
  name: 'ProjectsCreateProjectModal',
  components: {
    ProjectsArea,
    locationField: AppGoogleLocationField,
  },
  data: () => ({
    createProjectData: {
      projectName: '',
      phase: '',
      number: '',
      address: '',
      location: '',
      baseBudget: '',
      area: '',
      sourceLibraryId: '',
      powerDensity: '',
      areaUnit: '',
    },
    showLibraryField: false,
    TYPE_GEOCODE,
  }),
  validations: {
    createProjectData: {
      projectName: {
        required,
      },
    },
  },
  computed: {
    ...mapState(['showCreateProjectModal']),
    ...mapState({
      showSpinner: state => state.showSpinner,
    }),
    ...mapGetters('Libraries', ['getCustomLibraries']),
    ...mapGetters('UserRoles', ['canCreateProjectWithSourceLibrary']),
    ...mapGetters('Workspace', ['getActiveWorkspaceRole']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    renderAddress() {
      const { location, address } = this.createProjectData;
      const addressObject = {
        location,
        address,
      };
      const paramToOmit = address ? 'location' : 'address';
      return omit(addressObject, [paramToOmit]);
    },
    renderAddressText() {
      const { location, address } = this.createProjectData;
      return address?.formatted_address || location;
    },
    areaUnitTitle() {
      return getAreaUnitTitleByValue(this.createProjectData?.areaUnit);
    },
    dialog: {
      get() {
        return this.showCreateProjectModal;
      },
      set() {
        this.$store.commit('showModalAction', 'showCreateProjectModal');
      },
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.clearCreateForm();
      } else {
        this.getLibrariesList();
      }
    },
    showLibraryField(val) {
      if (!val) {
        this.createProjectData.sourceLibraryId = '';
      }
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions(['handleError']),
    ...mapActions({
      getLibrariesList: 'Libraries/getLibrariesList',
      setCreatedProject: 'Projects/setCreatedProject',
    }),
    GET_TEXT_REASON,
    errorsMessages(item) {
      const errors = [];
      if (!this.$v.createProjectData[item].$dirty) return errors;
      if (!this.$v.createProjectData[item].required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      return errors;
    },
    isNumber(evt) {
      Validations.isInteger(evt);
    },
    async createProject() {
      if (this.showSpinner) {
        return;
      }
      const workspaceId = this.activeWorkspaceId;
      this.$v.createProjectData.$touch();
      if (!this.$v.createProjectData.$invalid) {
        this.spinner(true);
        try {
          const {
            sourceLibraryId,
            baseBudget,
            area,
            powerDensity,
            ...otherProjectData
          } = this.createProjectData;
          const payload = {
            ...omit(otherProjectData, ['location', 'address']),
            ...this.renderAddress,
            ...(this.showLibraryField && sourceLibraryId) && {
              sourceLibraryId,
            },
            baseBudget: baseBudget || 0,
            area: area || 0,
            powerDensity: powerDensity || 0,
            workspaceId,
          };
          const deprecatedCreateProject = 'deprecatedCreateProjectV2';
          const { data } = await ProjectsApi[deprecatedCreateProject](payload);
          this.dialog = false;
          if (this.$route.name === 'projects') {
            let project = data.response;
            this.setCreatedProject(project);
          } else {
            this.$router.push({
              name: 'projects',
            });
          }
        } catch (err) {
          this.handleError(err);
          console.log(err);
        } finally {
          this.spinner(false);
        }
      }
    },
    clearCreateForm() {
      this.$refs.createProjectForm.reset();
      this.$v.createProjectData.$reset();
    },
  },
};
</script>
