export const getters = {
  getFeatureFlagEnabling: (state, getters) => (name, upperCase = true) => {
    if (upperCase) {
      name = name.toUpperCase();
    }
    const { mappedFeatureFlags } = getters;
    const { [name]: flag } = mappedFeatureFlags || {
    };
    const { value, enabled } = flag ?? {
    };
    if (value) {
      return value[value.type];
    }
    return enabled;
  },
  mappedFeatureFlags(state) {
    const { listFeatureFlags } = state;
    if (!listFeatureFlags) return {
    };
    return listFeatureFlags.reduce((result, option) => {
      const { name, ...rest } = option ?? {
      };
      return {
        ...result,
        [name]: rest,
      };
    }, {
    });
  },
  maintenanceMode(state, getters) {
    const { getFeatureFlagEnabling } = getters;
    const readOnlyMode = getFeatureFlagEnabling('VUE_APP_MAINTENANCE_MODE');
    return readOnlyMode;
  },
  showWorkspaceAddMemberModal(state, getters) {
    const { getFeatureFlagEnabling } = getters;
    return getFeatureFlagEnabling('VUE_APP_WORKSPACES_ADD_MEMBER_MODAL');
  },
  useSkeleton(state, getters) {
    return getters.getFeatureFlagEnabling('VUE_APP_SKELETON');
  },
  isShowCollectionEmbedWindowSize(state, getters) {
    return getters.getFeatureFlagEnabling('COLLECTION_EMBED_WINDOW_SIZE');
  },
  isUsedQuickIndex(state, getters) {
    return getters.getFeatureFlagEnabling('USE_QUICK_INDEX');
  },
  isVisiblePrintButton(state, getters) {
    return getters.getFeatureFlagEnabling('PRINT_VIEW_BUTTON');
  },
  isToggleToMoveToVerifiedArea(state, getters) {
    return getters.getFeatureFlagEnabling('TOGGLE_TO_MOVE_TO_VERIFIED_AREA');
  },
  navigationWithKeyboardInSchedule(state, getters) {
    return getters.getFeatureFlagEnabling('NAVIGATION_WITH_KEYBOARD_IN_SCHEDULE');
  },
  backButtonToCloseModalWindows(state, getters) {
    return getters.getFeatureFlagEnabling('BACK_BUTTON_TO_CLOSE_MODAL_WINDOWS');
  },
  maintenanceMessage(state, getters) {
    const { getFeatureFlagEnabling } = getters;
    const customMessage = getFeatureFlagEnabling('VUE_APP_MAINTENANCE_MODE_MESSAGE_CUSTOM');
    const defaultMessage = getFeatureFlagEnabling('VUE_APP_MAINTENANCE_MODE_MESSAGE_DEFAULT');
    return customMessage ? customMessage : defaultMessage;
  },
  useIncreaseCaching(state, getters) {
    return getters.getFeatureFlagEnabling('INCREASE_CACHING');
  },
  useProcoreConnection(state, getters) {
    return getters.getFeatureFlagEnabling('VUE_APP_PROCORE_CONNECTION_EXPORT');
  },
  useScheduleViewAndFieldsModifications(state, getters) {
    return getters.getFeatureFlagEnabling('schedule_view_and_fields_modifications');
  },
  useUpdateReferencedProductWithProjectDataV2(state, getters) {
    return getters.getFeatureFlagEnabling('update_referenced_product_with_project_data_v2');
  },
  useInvitingWorkspaceMemberMessage(state, getters) {
    return getters.getFeatureFlagEnabling('inviting_workspace_member_message');
  },
  useCollaboratorsRenamedToFollowers(state, getters) {
    return getters.getFeatureFlagEnabling('collaborators_renamed_to_followers');
  },
  useAddCollectionFollowButtonsToQuickLinks(state, getters) {
    return getters.getFeatureFlagEnabling('add_collection_follow_buttons_to_quick_links');
  },
  useToggleToMoveToVerifiedArea(state, getters) {
    return getters.getFeatureFlagEnabling('toggle_to_move_to_verified_area');
  },
  useCollaboratorsRevisedToFree(state, getters) {
    return getters.getFeatureFlagEnabling('collaborators_revised_to_free');
  },
  useProductRefEqualCreateNewProduct(state, getters) {
    return getters.getFeatureFlagEnabling('product_equal_and_reference_create_new_product');
  },
  useNewRequestsForFollow(state, getters) {
    return getters.getFeatureFlagEnabling('visibility_issues_in_sorting_of_collections');
  },
  useActionsProductFromSearch(state, getters) {
    return getters.getFeatureFlagEnabling('show_actions_for_my_products_in_search_results');
  },
  useRemoveUpgradeNotice(state, getters) {
    return getters.getFeatureFlagEnabling('turn_off_viewer_upgrade_notice');
  },
  verticalScrollInMultilineTextField(state, getters) {
    return getters.getFeatureFlagEnabling('vertical_scroll_in_multiline_text_field');
  },
  useRemoveEditWarningForFollowedProducts(state, getters) {
    return getters.getFeatureFlagEnabling('edit_followed_products_without_warning');
  },
  getCollectionsLimit_100(state, getters) {
    return getters.getFeatureFlagEnabling('get_collections_limit_100');
  },
  useEditedFollowComparisonTool(state, getters) {
    return getters.getFeatureFlagEnabling('edited_follow_comparison_tool');
  },
  useNewPdfLibrary(state, getters) {
    return getters.getFeatureFlagEnabling('use_new_pdf_library');
  },
  usePlatformPricingUpdates(state, getters) {
    return getters.getFeatureFlagEnabling('platform_pricing_updates');
  },
  useAddProductRecordLinkInQuickView(state, getters) {
    return getters.getFeatureFlagEnabling('add_product_record_link_in_quick_view');
  },
  useRemoveUpdateWarning(state, getters) {
    return getters.getFeatureFlagEnabling('remove_update_product_warning');
  },
  useFollowLaterTradeshowFlow(state, getters) {
    return getters.getFeatureFlagEnabling('follow_later_for_tradeshow_flow');
  },
  useScheduleMultipleSelect(state, getters) {
    return getters.getFeatureFlagEnabling('SCHEDULE_MULTIPLE_SELECT');
  },
  useLightfairRequiredFields(state, getters) {
    return getters.getFeatureFlagEnabling('lightfair_required_fields');
  },
  useMakePageFollowable(state, getters) {
    return getters.getFeatureFlagEnabling('make_page_followable');
  },
  mobileViewOptimizationsForTradeShow(state, getters) {
    return getters.getFeatureFlagEnabling('mobile_view_optimizations_for_trade_show');
  },
  multiSelectCustomValuesQuickAdd(state, getters) {
    return getters.getFeatureFlagEnabling('multi_select_custom_values_quick_add');
  },
  creatorUnableToCreateFollowProducts(state, getters) {
    return getters.getFeatureFlagEnabling('creator_unable_to_create_follow_products');
  },
  addTextNoteToEqualField(state, getters) {
    return getters.getFeatureFlagEnabling('add_text_note_to_equal_field');
  },
  workspaceSelectorOnPages(state, getters) {
    return getters.getFeatureFlagEnabling('workspace_selector_on_pages');
  },
  createAnchorForCollections(state, getters) {
    return getters.getFeatureFlagEnabling('create_anchor_for_collection');
  },
  verifiedAreaListing(state, getters) {
    return getters.getFeatureFlagEnabling('verified_area_listing');
  },
  /**
   * collection logo was displayed as collage which is hidden now
   * to reduce loading times
   */
  hideCollectionCollage(state, getters) {
    return getters.getFeatureFlagEnabling('hide_collection_collage');
  },
  createNewProjectView(state, getters) {
    return getters.getFeatureFlagEnabling('create_new_project_view');
  },
  createNewCollectionProducts(state, getters) {
    return getters.getFeatureFlagEnabling('create_new_collection_products');
  },
  /**
   * show AI chat bot at the bottom of the page - from airops
   */
  showAIChat(state, getters) {
    return getters.getFeatureFlagEnabling('show_ai_chat');
  },
  collaboratorCanUseExportFunctionality(state, getters) {
    return getters.getFeatureFlagEnabling('collaborator_can_use_export_functionality');
  },
  showUpdatesToWorkspaceGuests(state, getters) {
    return getters.getFeatureFlagEnabling('show_updates_to_workspace_guests');
  },
  /**
   * Move tips and tricks in product modal to right panel
   */
  moveTipsAndTricks(state, getters) {
    return getters.getFeatureFlagEnabling('move_tips_and_tricks');
  },
  lessRefreshing(state, getters) {
    return getters.getFeatureFlagEnabling('less_refreshing');
  },
  useLazyLoading(state, getters) {
    return getters.getFeatureFlagEnabling('use_lazy_loading');
  },
  projectsTableOptimization(state, getters) {
    return getters.getFeatureFlagEnabling('projects_table_optimization');
  },
  optimizationsVerifiedWorkspaces(state, getters) {
    return getters.getFeatureFlagEnabling('optimizations_verified_workspaces');
  },
};
