import ProjectsApi from '@/services/graphql/projects';
import ColoredIconsApi from '@/services/graphql/coloredIcons';
import { IS_STORE_ROOT } from '@/constants';
import { Storage } from 'aws-amplify';
export const actions = {
  async getProjectById({ commit, rootState, dispatch }, { id, sharedLink, showModal }) {
    if (showModal) {
      commit('spinner', true, IS_STORE_ROOT);
    }
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const response = await ProjectsApi.getProjectById({
        id,
        workspaceId,
        sharedLink,
      });
      const projectData = response.data.getProjectById;
      const { projectIcon, projectColor } = projectData;
      commit('setProjectIcon', projectIcon);
      commit('setProjectColor', projectColor);
      commit('setDetailDataOfProject', projectData);
      dispatch('getWorkspaceLogoToProjectDetails');
    } catch (err) {
      console.log('err getProjectById', err);
    } finally {
      if (showModal) {
        commit('spinner', false, IS_STORE_ROOT);
      }
    }
  },
  async getWorkspaceLogoToProjectDetails({ commit, getters, rootGetters }) {
    const { workspaceLogoKey: key } = getters;
    const { userId } = rootGetters;
    if (userId || !key) {
      commit('setWorkspaceLogoToProject', null);
      return;
    }
    try {
      const url = await Storage.get(key,
        {
          level: 'public',
        });
      commit('setWorkspaceLogoToProject', url);
    } catch (err) {
      console.log('getWorkspaceLogoToProjectDetails', err);
    }
  },
  async setColorIcon({ commit, dispatch, rootState }, { colorId, projectId, iconId }) {
    commit('spinner', true, IS_STORE_ROOT);
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await ColoredIconsApi.setColorIcon({
        colorId,
        projectId,
        iconId,
        workspaceId,
      });
      const { projectIcon, projectColor } = data.response;
      commit('setProjectIcon', projectIcon);
      commit('setProjectColor', projectColor);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
};
