import { API } from 'aws-amplify';
import {
  USER_INFO,
  COLLECTION_PERMISSION,
  MEMBER,
  LIBRARY,
  LIBRARY_V1, COLLECTION_PERMISSION_WITH_COMMENTS,
} from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
const LIBRARY_PERMISSION = `
  {
    creator ${USER_INFO}
    status
    collectionCount
    docType
    id
    name
    logo
    description
    lastModified
    workspaceId
    type
    kind
    members ${MEMBER}
  }
`;
const LIBRARY_PERMISSION_NO_BRACKETS = `
  creator ${USER_INFO}
  status
  collectionCount
  docType
  id
  name
  logo
  description
  lastModified
  workspaceId
  type
  kind
  members ${MEMBER}
`;
const LIBRARY_CUSTOM_COLLECTION = `
    workspace ${COLLECTION_PERMISSION}
    private ${COLLECTION_PERMISSION}
    published ${COLLECTION_PERMISSION}
    shared ${COLLECTION_PERMISSION_WITH_COMMENTS}
    followed ${COLLECTION_PERMISSION}
    other ${COLLECTION_PERMISSION_WITH_COMMENTS}
    nextToken
`;
const LIBRARY_PERMISSION_CONNECTION_NO_BRACKETS = `
  data ${LIBRARY_PERMISSION}
  amount
  nextToken
`;
export default {
  listLibraries(variables) {
    const query = new requestBuilder({
      requestName: 'listMyLibraries',
      requestType: GRAPHQL_TYPES.QUERY,
      requestVariables: {
        workspaceId: 'String',
        limit: 'Int',
        nextToken: 'String',
      },
      response: LIBRARY_PERMISSION_CONNECTION_NO_BRACKETS,
    }).buildQuery();
    return API.graphql({
      query, variables,
    });
  },
  listCustomLibraryCollections(variables) {
    const query = new requestBuilder({
      requestName: 'listCustomLibraryCollections',
      requestType: GRAPHQL_TYPES.QUERY,
      requestVariables: {
        libraryId: 'String',
        limit: 'Int',
        nextToken: 'String',
        workspaceId: 'String',
      },
      response: LIBRARY_CUSTOM_COLLECTION,
    }).buildQuery();
    return API.graphql({
      query, variables,
    });
  },
  listResentlyCollections(variables) {
    const query = new requestBuilder({
      requestName: 'listResentlyCollections',
      requestType: GRAPHQL_TYPES.QUERY,
      requestVariables: {
        libraryId: 'String!',
        nextToken: 'String',
        workspaceId: 'String!',
      },
      response: `data {
        ${LIBRARY_CUSTOM_COLLECTION}
      }
    `,
    }).buildQuery();
    return API.graphql({
      query, variables,
    });
  },
  getLibrary(variables) {
    const query = new requestBuilder({
      requestName: 'getLibrary',
      requestVariables: {
        projectId: 'String!',
        workspaceId: 'String',
      },
      response: `id`,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  getLibraryInfo(variables) {
    const query = `
      query getLibraryInfo_v2 (
        $libraryId: String!
        $workspaceId: String
      ) {
        response: getLibraryInfo_v2(
          libraryId: $libraryId
          workspaceId: $workspaceId
        )
          ${LIBRARY}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  shareLibrary(variables) {
    const query = new requestBuilder({
      requestName: 'shareLibrary',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        permissionType: 'SharingPermissionType!',
        sharingUserId: 'String!',
        libraryId: 'String!',
        emailMessage: 'String',
        workspaceId: 'String',
      },
      response: LIBRARY_PERMISSION_NO_BRACKETS,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  updateLibraryPermission(variables) {
    const query = new requestBuilder({
      requestName: 'updateLibraryPermission',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        permissionType: 'SharingPermissionType!',
        targetUserId: 'String!',
        libraryId: 'String!',
        workspaceId: 'String!',
      },
      response: LIBRARY_PERMISSION_NO_BRACKETS,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  inviteUserToLibrary(variables) {
    const query = new requestBuilder({
      requestName: 'inviteUserToLibrary',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        permissionType: 'SharingPermissionType!',
        email: 'String!',
        libraryId: 'String!',
        emailMessage: 'String',
        workspaceId: 'String',
      },
      response: LIBRARY_PERMISSION_NO_BRACKETS,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  deleteLibraryPermission(variables) {
    const query = new requestBuilder({
      requestName: 'deleteLibraryPermission',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        targetUserId: 'String!',
        libraryId: 'String!',
        workspaceId: 'String',
      },
      response: LIBRARY_PERMISSION_NO_BRACKETS,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  update(variables) {
    const query = `
      mutation updateLibrary (
        $libraryId: String!
        $name: String
        $description: String
        $logo: String
        $workspaceId: String
      ) {
        response: updateLibrary(
          libraryId: $libraryId
          name: $name
          description: $description
          logo: $logo
          workspaceId: $workspaceId
        ) ${LIBRARY_V1}
      }`;
    return API.graphql({
      query,
      variables,
    });
  },
  create(variables) {
    const query = `
      mutation createLibrary (
        $name: String!
        $workspaceId: String
      ) {
        response: createLibrary(
          name: $name
          workspaceId: $workspaceId
        ) ${LIBRARY_PERMISSION}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  createV2(variables) {
    const query = `
      mutation createLibraryV2 (
        $name: String!
        $workspaceId: String
      ) {
        response: createLibraryV2(
          name: $name
          workspaceId: $workspaceId
        ) ${LIBRARY_PERMISSION}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  deleteLibrary(variables) {
    const query = `
      mutation deleteLibrary (
        $libraryId: String!
        $workspaceId: String
      ) {
        response: deleteLibrary(
          libraryId: $libraryId
          workspaceId: $workspaceId
        ) {
          id
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  listLibrariesWithCollection(variables) {
    return new requestBuilder({
      requestName: 'listLibrariesWithCollection',
      requestVariables: {
        workspaceId: 'String!',
        collectionId: 'String!',
        limit: 'Int',
        nextToken: 'String',
      },
      response: LIBRARY_PERMISSION_CONNECTION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
};
