<template>
  <v-row class="main-app-banner">
    <p
      class="mb-0 mainBlue--text">
      {{ massage }}
    </p>
    <v-icon
      class="main-app-banner__close-icon"
      @click="$emit('close')">
      mdi-close
    </v-icon>
  </v-row>
</template>
<script>
export default {
  name: 'AppBanner',
  props: {
    massage: {
      type: String,
      default: () => '',
    },
  },
  computed: {
  },
};
</script>
<style scoped>
  .main-app-banner {
    z-index: 999998;
  }
</style>
