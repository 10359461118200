const EXTRA_TALL = 'Extra-tall';
const TALL = 'Tall';
const MEDIUM = 'Medium';
const SMALL = 'Small';
const ROW_ADDITIONAL_STEP = 36;
const HEIGHT_OF_ROWS = {
  [EXTRA_TALL]: 128,
  [TALL]: 88,
  [MEDIUM]: 56,
  [SMALL]: 32,
};
export {
  EXTRA_TALL,
  TALL,
  MEDIUM,
  SMALL,
  HEIGHT_OF_ROWS,
  ROW_ADDITIONAL_STEP,
};