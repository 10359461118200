import { API } from 'aws-amplify';
import requestBuilder from '@/services/graphql/requestBuilder';
import {
  USER_INFO,
  USER_INFO_NO_BRACKETS,
  THUMBNAIL,
  TEMPORARY_CREDENTIALS,
  RESPONSE, WORKSPACE_SETTINGS, COMMENTS_USER_DATA, RESOURCE_PERMISSION, PROJECT_SCHEDULE,
} from './fragments';
import { GRAPHQL_TYPES } from '@/constants/cores';
const SearchUsersV4 = `
  data ${USER_INFO}
  nextToken
`;
export default {
  getSchedule(variables) {
    return new requestBuilder({
      requestName: 'getSchedule',
      requestVariables: {
        projectId: 'String!',
        sharedLink: 'Boolean',
        workspaceId: 'String',
      },
      response: PROJECT_SCHEDULE,
      authMode: 'AWS_IAM',
      variables,
    }).processRequest();
  },
  getScheduleId(variables) {
    return new requestBuilder({
      requestName: 'getSchedule',
      requestVariables: {
        projectId: 'String!',
        sharedLink: 'Boolean',
        workspaceId: 'String',
      },
      response: `
        id
      `,
      authMode: 'AWS_IAM',
      variables,
    }).processRequest();
  },
  searchUsersV4(variables) {
    return new requestBuilder({
      requestName: 'searchUsersV4',
      requestVariables: {
        searchString: 'String!',
        nextToken: 'String',
      },
      response: SearchUsersV4,
      variables,
    }).processRequest();
  },
  searchUsersV2(variables) {
    return new requestBuilder({
      requestName: 'searchUsersV2',
      requestVariables: {
        searchString: 'String!',
      },
      response: USER_INFO_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  searchProjectMembers(variables) {
    return new requestBuilder({
      requestName: 'searchProjectMembers',
      requestVariables: {
        searchString: 'String!',
        projectId: 'String!',
        workspaceId: 'String',
      },
      response: USER_INFO_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  searchProjectMembersV2(variables) {
    return new requestBuilder({
      requestName: 'searchProjectMembersV2',
      requestVariables: {
        searchString: 'String!',
        projectId: 'String!',
        workspaceId: 'String',
      },
      response: SearchUsersV4,
      variables,
    }).processRequest();
  },
  declareAttachement(variables) {
    const query = `mutation declareAttachementMutation(
      $col:String!
      $rowId:String!
      $tableId:String!
      $tableType:TableType!
      ){
        response:declareAttachment(
          col: $col
          rowId:$rowId
          tableId:$tableId
          tableType:$tableType
        ){
          id
        }

    }`;
    return API.graphql({
      query,
      variables,
    });
  },
  getAttachmentInfo(variables) {
    const query = `
      query getAttachmentInfo (
        $id: String!
      ) {
        response: getAttachmentInfo(
          id: $id
          ) {
            filename
            size
            id
            type
            url
            thumbnails {
              ${THUMBNAIL}
            }
            key
          }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  declareUserAttachement(abortController) {
    const query = `mutation declareUserAttachment{
        response:declareUserAttachment{
          id
        }
    }`;
    const req = API.graphql({
      query,
    });

    abortController?.addRequest(req);
    return req;
  },
  resetPassword(variables) {
    const query = `
      mutation resetPassword ($email: String!) {
        resetPassword(email: $email)
      }
    `;
    return API.graphql({
      query,
      variables,
      authMode: 'AWS_IAM',
    });
  },
  getTemplateUrl(variables) {
    const query = `
    query getTemplateUrl($filename: String!){
      response: getTemplateUrl(filename: $filename)
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getTemporaryCredentials(variables) {
    return new requestBuilder({
      requestName: 'getTemporaryCredentials',
      requestVariables: {
        uuid: 'String!',
      },
      response: TEMPORARY_CREDENTIALS,
      authMode: 'AWS_IAM',
      variables,
    }).processRequest();
  },
  acceptTermsOfService(variables) {
    return new requestBuilder({
      requestName: 'acceptTermsOfService',
      requestType: 'mutation',
      response: `name`,
      variables,
    }).processRequest();
  },
  verifyEmail() {
    return new requestBuilder({
      requestName: 'verifyEmail',
      requestType: 'mutation',
      response: RESPONSE,
    }).processRequest();
  },
  getOnboardingRoute(variables) {
    const query = `
     mutation getOnboardingRoute($username: String!){
       response: getOnboardingRoute(username: $username)
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getMyRoleInResource(variables) {
    const query = new requestBuilder({
      requestName: 'getMyRoleInResource',
      requestType: GRAPHQL_TYPES.QUERY,
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'ResourceType!',
        resourceId: 'String!',
      },
    }).buildQuery();
    return API.graphql({
      query, variables,
    });
  },
  getWorkspaceSectionSettings(variables) {
    const query = new requestBuilder({
      requestName: 'getWorkspaceSectionSettings',
      requestType: GRAPHQL_TYPES.QUERY,
      requestVariables: {
        resourceType: 'ResourceType!',
        workspaceId: 'String!',
        libraryId: 'String',
      },
      response: WORKSPACE_SETTINGS,
    }).buildQuery();
    return API.graphql({
      query, variables,
    });
  },
  searchResourceMembers(variables) {
    return new requestBuilder({
      requestName: 'searchResourceMembers',
      requestVariables: {
        searchString: 'String!',
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
      },
      response: COMMENTS_USER_DATA,
      variables,
    }).processRequest();
  },
  getResourcePermission(variables) {
    return new requestBuilder({
      requestName: 'getResourcePermission',
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'CommentResourceType!',
        resourceId: 'String!',
      },
      response: RESOURCE_PERMISSION,
      variables,
    }).processRequest();
  },
  updateTimestampLastAccessed(variables) {
    return new requestBuilder({
      requestName: 'updateTimestampLastAccessed',
      requestVariables: {
        resourceType: 'TimestampResource!',
        resourceId: 'String!',
        userId: 'String',
      },
      requestType: GRAPHQL_TYPES.MUTATION,
      variables,
    }).processRequest();
  },
  getMyRolesInResource(variables) {
    return new requestBuilder({
      requestName: 'getMyRolesInResource',
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'ResourceType!',
        resourceId: 'String!',
      },
      requestType: GRAPHQL_TYPES.QUERY,
      response: `
          roles
          direct
          libraries
      `,
      variables,
    }).processRequest();
  },
};
