import {
  TYPE_LIBRARY_CUSTOM, TYPE_LIBRARY_DEFAULT,
} from '@/constants';
export const getters = {
  getLibraryById: state => id => {
    const baseLibrary = {
      name: 'Community',
    };
    if (!state.librariesList?.length) {
      return baseLibrary;
    }
    return state.librariesList.find(library => library.id == id) || baseLibrary;
  },
  getLibraryByType: state => libraryType => state.librariesList.filter(({ kind }) => kind == libraryType),
  getDefaultLibraries: (state, getters) => getters.getLibraryByType(TYPE_LIBRARY_DEFAULT),
  getDefaultLibraryById: (state, getters) => lId => getters.getDefaultLibraries.find(({ id }) => id == lId), // or community library or default library
  getCustomLibraries: (state, getters) => getters.getLibraryByType(TYPE_LIBRARY_CUSTOM),
  getCustomLibraryById: (state, getters) => lId => getters.getCustomLibraries.find(({ id }) => id == lId),
  getDefaultLibraryId (state, getters, rootState, rootGetters) {
    return rootGetters['Workspace/isPersonalWorkspace'] ? rootGetters.userId : rootState.Workspace.activeWorkspaceId ;
  },
};
