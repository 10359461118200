<template>
  <div class="share-autocomplete">
    <p
      class="small-p mb-0 share-autocomplete__invite-to-message">
      <span v-show="showInviteMessage">
        {{ inviteMessage }}
      </span>
    </p>
    <v-combobox
      ref="multyCombobox"
      v-model="selected"
      :items="users"
      :menu-props="{
        maxHeight:160
      }"
      :attach="attach"
      filled
      chips
      dense
      deletable-chips
      :placeholder="placeholder"
      multiple
      :error="!!selectErrors"
      autocomplete="off"
      :search-input.sync="search"
      no-filter
      hide-details
      hide-no-data
      hide-selected
      class="v-autocomplete__share-groups"
      item-value="id"
      item-text="email"
      return-object
      @change="change"
      @blur="onBlur">
      <template #selection="{ selected, attrs, item, select }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          close-icon="mdi-close"
          :class="[higlightUsers && (item.paymentStatus ? 'v-chip__paid-user' : 'v-chip__free-user')]"
          color="#fff"
          @dblclick="copyToClipBoard(item)"
          @click="select"
          @click:close="remove(item)">
          <v-icon
            v-if="!isValidEmail(item)"
            color="red"
            small>
            mdi-alert
          </v-icon>
          <AvatarImage
            v-else
            :avatar="avatars[item.picture]"
            :avatar-key="item.picture"
            :color="typeof item === 'object' && item.id ? item.id : '67BED3'"
            :size="20"
            position="left" />
          <p
            v-if="typeof item === 'object'"
            class="small-p mb-0"
            style="color: #515E61">
            {{ item.name }}
          </p>
          <p
            v-else
            class="small-p mb-0"
            style="color: #515E61">
            {{ item }}
          </p>
        </v-chip>
      </template>
      <template #item="{ item }">
        <template v-if="typeof item !== 'object'">
          <v-list-item-content :inner-text.prop="item" />
        </template>
        <UserListItem
          v-else
          :item="item"
          :avatar="avatars[item.picture]" />
      </template>
    </v-combobox>
  </div>
</template>
<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex';
import API from '@/services/graphql';
import {
  isValidEmail, copyToClipBoard,
} from '@/utils';
import {
  PROJECT, COLLECTION, LIBRARY, WORKSPACE,
} from '@/constants/cores';
import { DEBOUNCE_TIME_FOR_SEARCHING } from '@/constants';
import { debounce } from 'lodash';
export default {
  name: 'AppShareAutocomplete',
  components: {
    UserListItem: () => import('./AppShareAutocompleteUserListItem'),
  },
  props: {
    attach: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'Add people and groups',
    },
    blur: {
      type: Boolean,
      default: false,
    },
    usersInitial: {
      type: Array,
      default: () => [],
    },
    initialSelectedUser: {
      type: String,
      default: null,
    },
    sharedAutocomplete: {
      type: Boolean,
      default: false,
    },
    unRegisteredUsers: {
      type: Array,
      default: () => [],
    },
    collectionShare: {
      type: Boolean,
      default: false,
    },
    isProjectMembersRelated: {
      type: Boolean,
      default: false,
    },
    searchAction: {
      type: String,
      default: null,
    },
    selectErrors: {
      type: String,
      default: () => '',
    },
    inviteToTextType: {
      type: String,
      default: PROJECT,
      validator(value) {
        return [PROJECT, COLLECTION, LIBRARY, WORKSPACE].includes(value);
      },
    },
    inviteToMessage: {
      type: String,
      default: null,
    },
    checkEmailErrors: {
      type: Boolean,
      default: false,
    },
    higlightUsers: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchFilter: '',
    selected: [],
    initialUsers: [],
  }),
  computed: {
    ...mapState(['avatars', 'showSpinner', 'userInfo']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    getSearchAction() {
      const { searchAction: query } = this;
      if (query) return query;
      return 'searchUsersV4';
    },
    inviteMessage() {
      const { inviteToTextType, inviteToMessage } = this;
      return inviteToMessage || `Press enter to invite to ${inviteToTextType}`;
    },
    getUserBySearch() {
      return this.users?.find(e => e?.email === this.search) || null;
    },
    search: {
      get() {
        return this.searchFilter;
      },
      set(val) {
        this.searchFilter = val?.trim() || '';
      },
    },
    users: {
      get() {
        let users = this.initialUsers;
        if (this.sharedAutocomplete) {
          users = users.filter(user => user.name);
        }
        if (this.collectionShare) {
          users = users.filter(user => user.email !== this.userInfo?.email);
        }
        return users;
      },
      set(val) {
        this.initialUsers = val;
      },
    },
    showInviteMessage() {
      return this.search && this.sharedAutocomplete;
    },
  },
  watch: {
    initialSelectedUser: {
      handler(val) {
        if (val) {
          this.search = val;
        }
      },
      immediate: true,
    },
    search: debounce(function (val) {
      if (val) {
        this.searchOfUsers();
      } else if (!val && !this.selected.length) {
        this.users = [];
      } else {
        this.users = this.selected;
      }
    }, DEBOUNCE_TIME_FOR_SEARCHING),
    usersInitial: {
      handler() {
        if (this.blur || this.$route.name === 'schedule-library') {
          this.selected = this.usersInitial.filter(user => user?.id);
        }
      },
      deep: true,
      immediate: true,
    },
    async selected() {
      if (!this.selected.length && !this.initialSelectedUser) {
        this.users = [];
      }
      const selectedItem = this.selected?.[this.selected.length - 1];

      // If user is:
      // - selected from autocomplete/dropdown     : selectedItem = user Object
      // - selected from enter without autocomplete: selectedItem = string (of user email)
      if (typeof selectedItem === 'string') {
        const email = selectedItem;

        if (!this.checkEmailErrors) {
          if (!isValidEmail(email)) {
            this.selected.splice(-1, 1);
            return;
          }
        }
        if (this.unRegisteredUsers.length) {
          const existUnregisteredUser = this.unRegisteredUsers.some(user => user.userInfo.email === email);
          if (existUnregisteredUser) {
            this.openSnackBar({
              title: `You cannot change the role of a pending user ${email}`,
            });
            this.selected.splice(-1, 1);
          }
        }
      }
      this.$emit('selected', this.selected);
      this.search = '';
    },
  },
  mounted() {
    this.$refs.multyCombobox.focus();
  },
  methods: {
    ...mapActions(['getAvatar', 'handleError']),
    ...mapMutations(['spinner', 'openSnackBar']),
    isValidEmail,
    copyToClipBoard,
    change(selected) {
      const { blur, getUserBySearch } = this;
      const lastIndex = selected.length - 1;
      if (typeof selected[selected.length - 1] !== 'object' && blur) {
        this.selected.splice(lastIndex, 1);
      } else if (getUserBySearch) this.selected.splice(lastIndex, 1, getUserBySearch);
    },
    onBlur() {
      if (this.blur) {
        this.$emit('manageInput');
      }
    },
    remove(item) {
      const { selected } = this;
      if (!this.blur) {
        let index = -1;
        if (typeof item === 'object') {
          index = selected.findIndex(obj => obj.id === item.id);
        } else {
          index = this.selected.indexOf(item);
        }
        if (index >= 0) {
          this.selected.splice(index, 1);
        }
        if (this.$route.name === 'schedule-library') {
          this.$emit('remove', item.id);
        }
      } else {
        this.$emit('remove', item.id);
      }
    },
    async searchOfUsers({ nextToken } = {
    }) {
      this.spinner(true);
      const { getSearchAction: searchAction, search: searchString, isProjectMembersRelated } = this;
      try {
        const { data } = await API[searchAction]({
          searchString,
          nextToken,
          ...(isProjectMembersRelated && {
            projectId: this.$route.params.id,
            workspaceId: this.activeWorkspaceId,
          }),
        });
        this.compareSearchMatches({
          data,
          searchString,
          nextToken,
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    parseResponseData({ data, nextTokenForReq } = {
    }) {
      this.spinner(false);
      const { response: initData } = data || [];
      let users = [];
      let nextToken = null;
      if (Array.isArray(initData)) {
        users = initData;
      } else {
        const { data, nextToken: nextTokenResp } = initData ?? {
        };
        users = data;
        nextToken = nextTokenResp;
      }
      if (!nextTokenForReq) {
        this.users = [...this.selected, ...users];
      } else {
        this.users = [...this.users, ...users];
      }
      return {
        nextToken,
      };
    },
    compareSearchMatches({ data, searchString, nextToken: nextTokenForReq = false } = {
    }) {
      if ((searchString || this.search) && searchString !== this.search) {
        return;
      }
      const { nextToken } = this.parseResponseData({
        data,
        nextTokenForReq,
      }) || {
      };
      if (this.initialSelectedUser) {
        this.$refs.multyCombobox.focus();
        if (this.users.length === 1) {
          this.selected = this.users;
        }
      }
      this.users.forEach(async (item) => {
        if (item.picture && !this.avatars[item.picture]) {
          this.getAvatar({
            key: item.picture,
            getAvatars: true,
            context: this,
          });
        }
      });
      if (nextToken)
        this.searchOfUsers({
          nextToken,
        });
    },
  },
};
</script>
<style scoped lang="scss">
.share-autocomplete {
  position: relative;
  &__invite-to-message {
    position: absolute;
    z-index: 1;
    top: -24px;
    font-size: 15px !important;
    font-weight: bold;
  }
}
.v-select-list {
  max-height: 50vh !important;
}
::v-deep .v-list-item {
  margin-top: 20px !important;
  min-height: 40px !important;
  &--highlighted {
    &::before {
      opacity: 0 !important;
    }
    &:hover {
      &:before {
        opacity: 0.16 !important;
      }
    }
  }
}
</style>
