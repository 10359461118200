import { API } from 'aws-amplify';
import {
  USER_INFO,
  ROWS_DATA,
  THUMBNAIL,
  COLLECTION_PERMISSION,
  COLLECTION_PERMISSION_LEAN,
  LIBRARY_V1,
  FILE_STRUCTURE,
  COLLECTION_PERMISSION_V2,
  COLLECTION_SHARING_OPTIONS,
  COMMUNITY_COLLECTION,
  MEMBER,
  MEMBER_NO_BRACKET,
  COLLECTION_PERMISSION_WITH_COMMENTS_NO_BRACKETS,
  COLLECTION_PERMISSION_NO_BRACKETS, RECENTLY_COLLECTION_PERMISSION,
} from './fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
const follower = `
  {
    name
    email
    id
    picture
    logo
    color
  }
`;
const COLLECTION_WITH_FOLLOWERS_V2_NO_BRACKETS = `
    workspaceId
    id
    name
    description
    logo
    libraryId
    lastModified
    creator ${USER_INFO}
    status
    members ${MEMBER}
    followersInfo {
      count
      followers ${follower}
    }
    productsCount
    publish
    follow
    publishExpirationDate
    publishExpirationMessage
    author
    location
    address {
      formatted_address
    }
    editable
    createdProUser
    docType
    collectionGroup
    suggested
    pictures {
      attachment {
        ${FILE_STRUCTURE}
      }
    }
    isVerified
    verifiedPageId
    verifiedPageName
`;
const COLLECTION = `
  {
    workspaceId
    id
    name
    description
    logo
    libraryId
    lastModified
    creator ${USER_INFO}
    status
    members ${MEMBER}
    followers ${follower}
    productsCount
    publish
    follow
    publishExpirationDate
    publishExpirationMessage
    author
    location
    address {
      formatted_address
    }
    editable
    createdProUser
    docType
    collectionGroup
    suggested
    pictures {
      attachment {
        ${FILE_STRUCTURE}
      }
    }
  }
`;
const COLLECTION_COUNTER = `
    id
    productsCount
`;
const PERMISSION_CONNECTION = `
    data ${COLLECTION_PERMISSION}
    amount
    nextToken
`;
const PERMISSION_CONNECTION_LEAN = `
    data ${COLLECTION_PERMISSION_LEAN}
    amount
    nextToken
`;
const FOLLOWED_COLLECTION_CONNECTION = `
    data ${COMMUNITY_COLLECTION}
    amount
    nextToken
`;
const COLLECTION_PHOTO = `
  {
    id
    key
    declaredAt
    thumbnails {
      ${THUMBNAIL}
    }
  }
`;
const COLLECTION_PHOTO_CONNECTION = `
  {
    data ${COLLECTION_PHOTO}
    amount
    nextToken
  }
`;
const SHARED_LINK = `
    url
    accessToken
    collectionId
    workspaceId
    mode
    generatedAt
    creator
    fieldsToShow
`;
const SHARED_PAGE_LINK = `
    url
    accessToken
    pageId
    workspaceId
    mode
    generatedAt
    lastModified
    creator
`;
const COLLECTION_SUGGESTION = `
  createdProUser
  name
  libraryId
  workspaceId
  acceptance
  suggestionResource
  status
  id
  url
  productsCount
  suggestedAt
  logo
  creator ${USER_INFO}
  pictures {
    attachment {
      ${FILE_STRUCTURE}
    }
  }
`;
const COLLECTION_SUGGESTION_CONNECTION = `
  data {
    ${COLLECTION_SUGGESTION}
  }
  nextToken
`;
const IMPORT_DOCUMENT = `
  id
  workspaceId
  collectionId
  status
  processedCount
  startedAt
  endedAt
  errors
`;
export default {
  listMyCollections(variables) {
    return new requestBuilder({
      requestName: 'listMyCollections',
      requestVariables: {
        limit: 'Int',
        nextToken: 'String',
        libraryId: 'String',
        collectionGroup: 'CollectionGroup!',
        workspaceId: 'String',
        sortByDate: 'Boolean',
        forProduct: 'Boolean',
      },
      response: PERMISSION_CONNECTION_LEAN,
      variables,
    }).processRequest();
  },
  listFollowedCollections(variables) {
    return new requestBuilder({
      requestName: 'listFollowedCollections',
      requestVariables: {
        limit: 'Int',
        nextToken: 'String',
        libraryId: 'String',
        workspaceId: 'String',
        sortByDate: 'Boolean',
      },
      response: FOLLOWED_COLLECTION_CONNECTION,
      variables,
    }).processRequest();
  },
  listFollowedCollectionsLastAccessed(variables) {
    return new requestBuilder({
      requestName: 'listFollowedCollectionsLastAccessed',
      requestVariables: {
        limit: 'Int',
        nextToken: 'String',
        libraryId: 'String',
        workspaceId: 'String',
        scanIndexForward: 'Boolean',
      },
      response: FOLLOWED_COLLECTION_CONNECTION,
      variables,
    }).processRequest();
  },
  listMyCollectionsLastAccessed(variables) {
    return new requestBuilder({
      requestName: 'listMyCollectionsLastAccessed',
      requestVariables: {
        limit: 'Int',
        nextToken: 'String',
        libraryId: 'String',
        collectionGroup: 'CollectionGroup!',
        workspaceId: 'String',
        forProduct: 'Boolean',
      },
      response: PERMISSION_CONNECTION,
      variables,
    }).processRequest();
  },
  listCommunityCollections(variables) {
    return new requestBuilder({
      requestName: 'listCommunityCollections_v2',
      requestVariables: {
        limit: 'Int',
        nextToken: 'String',
        workspaceId: 'String',
        workspaceOnly: 'Boolean',
        accessToken: 'String',
        pageId: 'String',
      },
      response: `
        data ${COLLECTION_PERMISSION_V2}
        amount
        nextToken
      `,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  listMyCollectionSuggestions(variables) {
    return new requestBuilder({
      requestName: 'listMyCollectionSuggestions',
      requestVariables: {
        limit: 'Int',
        nextToken: 'String',
      },
      response: COLLECTION_SUGGESTION_CONNECTION,
      variables,
    }).processRequest();
  },
  shareCollection(variables) {
    const query = new requestBuilder({
      requestName: 'shareCollection',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        permissionType: 'SharingPermissionType!',
        sharingUserId: 'String!',
        collectionId: 'String!',
        emailMessage: 'String!',
        workspaceId: 'String',
      },
      response: COLLECTION_PERMISSION_WITH_COMMENTS_NO_BRACKETS,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  inviteUserToCollection(variables) {
    const query = new requestBuilder({
      requestName: 'inviteUserToCollection',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        permissionType: 'SharingPermissionType!',
        email: 'String!',
        collectionId: 'String!',
        emailMessage: 'String!',
        workspaceId: 'String',
      },
      response: COLLECTION_PERMISSION_WITH_COMMENTS_NO_BRACKETS,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  updateCollectionPermission(variables) {
    const query = new requestBuilder({
      requestName: 'updateCollectionPermission',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        permissionType: 'SharingPermissionType!',
        targetUserId: 'String!',
        collectionId: 'String!',
        workspaceId: 'String',
      },
      response: COLLECTION_PERMISSION_WITH_COMMENTS_NO_BRACKETS,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  deleteCollectionPermission(variables) {
    const query = new requestBuilder({
      requestName: 'deleteCollectionPermission',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionId: 'String!',
        targetUserId: 'String!',
        workspaceId: 'String',
      },
      response: COLLECTION_PERMISSION_WITH_COMMENTS_NO_BRACKETS,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  deleteCollection(variables) {
    const query = `
      mutation deleteCollection (
        $collectionId: String!
        $libraryId: String
        $workspaceId: String
      ) {
        response: deleteCollection(
          collectionId: $collectionId
          libraryId: $libraryId
          workspaceId: $workspaceId
          ) {
            id
          }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getLibraryCollectionInfo(variables) {
    return new requestBuilder({
      requestName: 'getLibraryCollectionInfo',
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
        accessToken: 'String',
      },
      variables,
      response: COLLECTION_WITH_FOLLOWERS_V2_NO_BRACKETS,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  getLibraryCollectionName({ hasAccessToOrigin = false, ...variables } = {
  }) {
    return new requestBuilder({
      requestName: hasAccessToOrigin ? 'getLibraryCollectionInfo' : 'getCommunityCollectionInfo',
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
      },
      variables,
      response: 'name',
    }).processRequest();
  },
  getCommunityCollectionInfo(variables) {
    return new requestBuilder({
      requestName: 'getCommunityCollectionInfo',
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
      },
      variables,
      response: COLLECTION_WITH_FOLLOWERS_V2_NO_BRACKETS,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  publishCollectionForCommunity(variables) {
    return new requestBuilder({
      requestName: 'publishCollectionForCommunity',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
      },
      responseWrapperText: 'publishCollectionForCommunity',
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  unpublishCollectionFromCommunity(variables) {
    return new requestBuilder({
      requestName: 'unpublishCollectionFromCommunity',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
      },
      responseWrapperText: 'unpublishCollectionFromCommunity',
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  getCollectionRows(variables) {
    const query = `
      query listStructuredCollectionRows (
        $collectionId: String!
        $limit: Int
        $nextToken: String
        $workspaceId: String
        $accessToken: String
        $libraryId: String
      ) {
        response: listStructuredCollectionRows(
          collectionId: $collectionId
          limit: $limit
          nextToken: $nextToken
          workspaceId: $workspaceId
          accessToken: $accessToken
          libraryId: $libraryId
        ) {
          ${ROWS_DATA}
        }
      }
    `;
    return API.graphql({
      query,
      variables,
      authMode: 'AWS_IAM',
    });
  },
  tradeShowSendEmail(variables) {
    return new requestBuilder({
      requestName: 'tradeShowSendEmail',
      requestType: GRAPHQL_TYPES.MUTATION,
      authMode: 'AWS_IAM',
      requestVariables: {
        email: 'String!',
        collectionName: 'String!',
        quickLink: 'String!',
      },
      variables,
    }).processRequest();
  },
  followCollection(variables) {
    return new requestBuilder({
      requestName: 'followCollection',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
        suggested: 'Boolean',
      },
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  followCollectionV2(variables) {
    return new requestBuilder({
      requestName: 'followCollectionV2',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
        suggested: 'Boolean',
      },
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  isCollectionFollowed(variables) {
    return new requestBuilder({
      requestName: 'isCollectionFollowed',
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String!',
      },
      response: `isFollowed`,
      variables,
    }).processRequest();
  },
  unfollowCollection(variables) {
    return new requestBuilder({
      requestName: 'unfollowCollection',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
      },
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  unfollowCollectionV2(variables) {
    return new requestBuilder({
      requestName: 'unfollowCollectionV2',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
      },
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  subscribeCollectionFollow(variables) {
    return new requestBuilder({
      requestName: 'subscribeCollectionFollow',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        workspaceId: 'String!',
      },
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  subscribeCollectionUnfollow(variables) {
    return new requestBuilder({
      requestName: 'subscribeCollectionUnfollow',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        workspaceId: 'String!',
      },
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  collectionsViews(variables) {
    const query = `
    query getCollectionViews(
      $collectionId: String
      $workspaceId: String
      $accessToken: String
      $libraryId: String
    ) {
      response:getCollectionViews (
        collectionId: $collectionId
        workspaceId: $workspaceId
        accessToken: $accessToken
        libraryId: $libraryId
      ) {
        columnOrder
        custom_dropdowns
        dropdowns {
          multipleSelect
          name
          values
        }
        filterTag
        id
        name
        role
        sortingField
        sortingIndex
        type
        visibleColumns
        customColumnOrder
        customDefaultFields
        columnsSize {
          id
          size
          minSize
          unit
        }
      }
    }
    `;
    return API.graphql({
      query,
      variables,
      authMode: 'AWS_IAM',
    });
  },
  collectionsSchema(variables) {
    const query = `
    query getCollectionsSchema(
      $collectionId: String
      $workspaceId: String
      $accessToken: String
      $libraryId: String
      ){
      response:getCollectionSchema(
        collectionId: $collectionId
        workspaceId: $workspaceId
        accessToken: $accessToken
        libraryId: $libraryId
      ){
        PK
        SK
        id
        requiredFields
        read_only_fields
        allowedFields
        schema {
          id
          arrayType
          dateType
          extra
          formulaType
          longPref
          maxLen
          multiline
          name
          shortPref
          type
          hint
        }
      }
    }`;
    return API.graphql({
      query,
      variables,
      authMode: 'AWS_IAM',
    });
  },
  listCollectionPhotos(variables) {
    const query = `
    query listCollectionPhotos(
      $collectionId: String!
      ){
      response:listCollectionPhotos(
        collectionId: $collectionId,
      ) ${COLLECTION_PHOTO_CONNECTION}
    }`;
    return API.graphql({
      query,
      variables,
      authMode: 'AWS_IAM',
    });
  },
  declareCollectionPhoto(variables) {
    const query = `
    mutation declareCollectionPhoto(
      $collectionId: String!
      $workspaceId: String
    ) {
      response: declareCollectionPhoto(
        collectionId: $collectionId
        workspaceId: $workspaceId
      ) {
        id
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getCollectionSharedLink(variables) {
    return new requestBuilder({
      requestName: 'getCollectionSharedLink',
      requestType: GRAPHQL_TYPES.QUERY,
      requestVariables: {
        workspaceId: 'String',
        collectionId: 'String!',
        accessToken: 'String',
        pageId: 'String',
      },
      response: SHARED_LINK,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  getWorkspaceSharedLink(variables) {
    return new requestBuilder({
      requestName: 'getWorkspacePageSharedLink',
      requestType: GRAPHQL_TYPES.QUERY,
      requestVariables: {
        workspaceId: 'String!',
        pageId: 'String!',
        accessToken: 'String',
      },
      response: SHARED_PAGE_LINK,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  resetWorkspacePageSharedLink(variables) {
    return new requestBuilder({
      requestName: 'resetWorkspacePageSharedLink',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        workspaceId: 'String!',
        pageId: 'String!',
      },
      response: SHARED_PAGE_LINK,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  updateCollectionSharedLink(variables) {
    return new requestBuilder({
      requestName: 'updateCollectionSharedLink',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
        mode: 'SharedMode',
        fieldsToShow: '[String]',
      },
      response: SHARED_LINK,
      variables,
    }).processRequest();
  },
  resetCollectionSharedLink(variables) {
    return new requestBuilder({
      requestName: 'resetCollectionSharedLink',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String!',
      },
      response: SHARED_LINK,
      variables,
    }).processRequest();
  },
  copyCollection(variables) {
    return new requestBuilder({
      requestName: 'copyCollection',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
        community: 'Boolean',
        accessToken: 'String',
        suggested: 'Boolean',
      },
      response: COLLECTION_PERMISSION_WITH_COMMENTS_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  deleteCollectionPhotos(variables) {
    const query = `
    mutation deleteCollectionPhotos(
      $collectionId: String!
      $photoIds: [String]!
      $workspaceId: String
    ) {
      deleteCollectionPhotos(
        collectionId: $collectionId
        photoIds: $photoIds
        workspaceId: $workspaceId
      ) {
        id
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getCollectionPhotoInfo(variables) {
    const query = `
    query getCollectionPhotoInfo(
      $collectionId: String!
      $photoId: String!
    ) {
      response: getCollectionPhotoInfo(
        collectionId: $collectionId
        photoId: $photoId
      ) ${COLLECTION_PHOTO}
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeCollectionPhoto(variables) {
    const query = new requestBuilder({
      requestName: 'subscribeMutationCollectionPhotoInfo',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        collectionId: 'String!',
        photoId: 'String!',
      },
      response: `
        collectionId
        photoId
        id
        key
        declaredAt
        thumbnails {
          ${THUMBNAIL}
        }
    `,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  update(variables) {
    const query = `
      mutation updateCollection (
        $collectionId: String!
        $libraryId: String
        $name: String
        $description: String
        $logo: String
        $author: String
        $location: String
        $workspaceId: String
        $address: AddressInput
      ) {
        response: updateCollection(
          collectionId: $collectionId
          libraryId: $libraryId
          name: $name
          description: $description
          logo: $logo
          author: $author
          location: $location
          workspaceId: $workspaceId
          address: $address
        ) ${COLLECTION}
      }`;
    return API.graphql({
      query,
      variables,
    });
  },
  create(variables) {
    return new requestBuilder({
      requestName: 'createCollection',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        name: 'String!',
        libraryId: 'String',
        workspaceId: 'String',
      },
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  createV2(variables) {
    return new requestBuilder({
      requestName: 'createCollectionV2',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        name: 'String!',
        libraryId: 'String',
        workspaceId: 'String',
      },
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  createV3(variables) {
    return new requestBuilder({
      requestName: 'createCollectionV3',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        name: 'String!',
        libraryId: 'String',
        workspaceId: 'String',
      },
      response: COLLECTION_PERMISSION_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  addCollectionToLibrary(variables) {
    const query = `
      mutation addCollectionToLibrary (
        $collectionId: String!
        $libraryIds: [String]!
        $workspaceId: String
      ) {
        response: addCollectionToLibrary(
          collectionId: $collectionId
          libraryIds: $libraryIds
          workspaceId: $workspaceId
        ) ${LIBRARY_V1}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  removeCollectionFromLibrary(variables) {
    const query = `
      mutation removeCollectionFromLibrary (
        $collectionId: String!
        $libraryId: String!
        $workspaceId: String!
      ) {
        response: removeCollectionFromLibrary(
          collectionId: $collectionId
          libraryId: $libraryId
          workspaceId: $workspaceId
        ) {
          id
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  listCollectionsForProduct(variables) {
    return new requestBuilder({
      requestName: 'listCollectionsForProduct',
      requestVariables: {
        projectId: 'String',
        workspaceId: 'String',
      },
      response: `
          projectSourceLibraryCollections {
            id
            name
            type
            readOnly
            productsCount
          }
          userCollections {
            id
            name
            type
            readOnly
            productsCount
          }`,
      variables,
    }).processRequest();
  },
  switchTypeSharing(variables) {
    return new requestBuilder({
      requestName: 'updateCollectionSharingOptions',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        type: 'CollectionSharingTypes!',
        collectionId: 'String!',
        enabled: 'Boolean',
      },
      response: COLLECTION_SHARING_OPTIONS,
      variables,
    }).processRequest();
  },
  getCollectionSharingOptions(variables, useIam = false) {
    return new requestBuilder({
      requestName: 'getCollectionSharingOptions',
      requestType: GRAPHQL_TYPES.QUERY,
      requestVariables: {
        collectionId: 'String!',
        accessToken: 'String',
      },
      response: COLLECTION_SHARING_OPTIONS,
      ...useIam && {
        authMode: 'AWS_IAM',
      },
      variables,
    }).processRequest();
  },
  suggestCollection(variables) {
    return new requestBuilder({
      requestName: 'suggestCollection',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        email: 'String!',
        collectionId: 'String!',
        emailMessage: 'String',
        force: 'Boolean',
      },
      response: COLLECTION_SUGGESTION,
      variables,
    }).processRequest();
  },
  ignoreSuggestion(variables) {
    return new requestBuilder({
      requestName: 'ignoreSuggestion',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        suggestionId: 'String!',
      },
      response: COLLECTION_SUGGESTION,
      variables,
    }).processRequest();
  },
  subscribeUpdateCollectionSharingOptions(variables) {
    const query = new requestBuilder({
      requestName: 'subscribeUpdateCollectionSharingOptions',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      requestVariables: {
        id: 'String!',
      },
      response: COLLECTION_SHARING_OPTIONS,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeProductsCountChange(variables) {
    const query = new requestBuilder({
      requestName: 'subscribeProductsCountChange',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      response: COLLECTION_COUNTER,
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  subscribeImportDocument(variables) {
    const query = new requestBuilder({
      requestName: 'subscribeImportDocument',
      requestType: GRAPHQL_TYPES.SUBSCRIPTION,
      response: IMPORT_DOCUMENT,
      requestVariables: {
        collectionId: 'String!',
        workspaceId: 'String',
      },
    }).buildQuery();
    return API.graphql({
      query,
      variables,
    });
  },
  listResentlyCollections(variables) {
    return new requestBuilder({
      requestName: 'listResentlyCollections',
      requestVariables: {
        libraryId: 'String!',
        workspaceId: 'String!',
        forProduct: 'Boolean',
        nextToken: 'String',
        collectionGroup: 'CollectionGroup',
      },
      response: RECENTLY_COLLECTION_PERMISSION,
      variables,
    }).processRequest();
  },
  getProductMode(variables) {
    return new requestBuilder({
      requestName: 'getProductMode',
      requestVariables: {
        productId: 'String!',
      },
      response: `PK mode`,
      variables,
    }).processRequest();
  },
  getSortedCollectionNameAcs(variables) {
    return new requestBuilder({
      requestName: 'listMyCollections',
      requestVariables: {
        limit: 'Int',
        nextToken: 'String',
        libraryId: 'String',
        collectionGroup: 'CollectionGroup!',
        workspaceId: 'String',
        sortByDate: 'Boolean',
        forProduct: 'Boolean',
      },
      response: PERMISSION_CONNECTION_LEAN,
      variables,
    }).processRequest();
  },
  getSortedCollectionNameDesc(variables) {
    return new requestBuilder({
      requestName: 'listMyCollections',
      requestVariables: {
        limit: 'Int',
        nextToken: 'String',
        libraryId: 'String',
        collectionGroup: 'CollectionGroup!',
        workspaceId: 'String',
        sortByDate: 'Boolean',
        forProduct: 'Boolean',
      },
      response: PERMISSION_CONNECTION_LEAN,
      variables,
    }).processRequest();
  },
  getMembersWithGrants(variables) {
    return new requestBuilder({
      requestName: 'getMembersWithGrants',
      requestVariables: {
        collectionId: 'String!',
        collectionName: 'String!',
        workspaceId: 'String!',
      },
      response: MEMBER_NO_BRACKET,
      variables,
    }).processRequest();
  },
};
