import currencyFormatter from 'currency-formatter';
import { Storage } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import {
  SHARED_PERMISSIONS_LEVEL,
  TYPE_COLLABORATOR,
  TYPE_CREATOR,
  TYPE_CREATOR_ADMIN,
  TYPE_EDITOR,
  TYPE_FOLLOWER,
  TYPE_OWNER, TYPE_VIEWER,
} from '@/constants/userPermissions';
import {
  flow, partialRight, keyBy, values, cloneDeep, isString, debounce,
} from 'lodash';
import {
  cacheControl, COLLECTION, WORKSPACE,
} from '@/constants/cores';
import {
  AREA_UNITS_MAPPING, FEET_TITLE,
} from '@/constants/projectArea';
import store from '@/store';
import {
  ROUTE_COMMUNITY_COLLECTION,
  COLLECTION_PRODUCTS_LIMIT,
} from '@/constants';
import Vue from 'vue';
import { SORT_TOP } from '@/constants/scheduleViews';
import { ROLES_BY_TYPE } from '@/constants/accessRolesList';
import { WORKSPACE_PAGE_RESOURCE } from '@/constants/resourceTypes';
import { hasAccessV2 } from '@/utils/accessLayer';
import FileSaver from 'file-saver';
import { PROFILE_REQUIRED_FIELDS } from '@/constants/account';
const filterHeadersGroup = ({ headers = [], groupItems = [], disabled = false }) => {
  if (disabled) return headers;
  const headersValues = headers.map(e => e.value);
  let result = headers;
  groupItems.forEach(({ items }) => {
    const [firstEl, secondEl] = items;
    const isFlexDoubletItems = items.every(e => headersValues.includes(e));
    if (isFlexDoubletItems) {
      const secondObject = result.find(e => e.value === secondEl);
      result = result.reduce((result, current) => {
        const el = current.value === firstEl ? [current, secondObject] : current.value === secondEl ? null : current;
        return !el ? [...result] : [...result, el];
      }, []);
    }
  });
  return result;
};
/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @param {Number} reduceProportions reduce to given proportions
 * @return {{width: number, ratio: number, height: number}}
 */
const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight, reduceProportions) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return {
    ratio,
    width: (srcWidth * ratio) * reduceProportions, height: srcHeight * ratio * reduceProportions,
  };
};
const isIdenticalIdArr = (first, second) => {
  if (first.length === second.length) {
    const arrSecondIds = second.map(e => e?.id || e);
    return first.every(e => arrSecondIds.includes(e?.id || e));
  }
  return false;
};
const adjustImageSize = (width, height, file) => {
  return new Promise((res, rej) => {
    const arr = file.name.split('.');
    const fileName = `${arr.slice(0, arr.length - 1).join('.')}.png`;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext('2d');
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, width, height);
        const hRatio = width / img.width;
        const vRatio = height / img.height;
        const ratio = Math.min(hRatio, vRatio);
        const centerShiftx = (width - img.width * ratio) / 2;
        const centerShifty = (height - img.height * ratio) / 2;
        ctx.drawImage(img, 0, 0, img.width, img.height,
          centerShiftx, centerShifty, img.width * ratio, img.height * ratio);
        ctx.canvas.toBlob((blob) => {
          const newFIle = new File([blob], fileName, {
            type: 'image/png',
            lastModified: Date.now(),
          });
          res(newFIle);
        }, 'image/png', 1);
      };
    };
    reader.onerror = error => rej(error);
  });
};
// get url search params
const getURLSearchParams = ({ item, url }) => url && item && new URL(url).searchParams.get(item);
const isUrlType = url => url instanceof URL;
const addSearchParams = (url, params = {
}) => {
  if (isString(url) && url) {
    url = new URL(url);
  }
  if (isUrlType(url)) {
    return new URL(
      `${url.origin}${url.pathname}?${new URLSearchParams([
        ...Array.from(url.searchParams.entries()),
        ...Object.entries(params),
      ])}`
    );
  }
  return {
  };
};
// format all price number to currency format
const formatCurrency = (amount, code = 'USD') => currencyFormatter.format(amount, {
  code,
});
const viewPrefix = 'scheduleView_';
const getViewFromStorage = (userId, projectId, wId) => window.localStorage.getItem(''.concat(viewPrefix, userId, '-', projectId, '-', wId));
const setViewInStorage = (userId, projectId, wId, viewId) => {
  window.localStorage.setItem(''.concat(viewPrefix, userId, '-', projectId, '-', wId), viewId);
};
const extractUserTeamRole = (value) => {
  const [category, role] = value ? value.split('+') : [];
  return {
    category,
    role,
  };
};
const parseStorageKey = (storageKey) => {
  const splittedArray = storageKey ? storageKey.split('/') : [];
  let [identityId, key, filename, thumbnailsDir] = [];
  if (splittedArray.length === 5 && splittedArray.includes('thumbnails')) {
    [, identityId, key, thumbnailsDir, filename] = splittedArray;
    key = `${key}/${thumbnailsDir}`;
  } else if (splittedArray.length === 3) {
    [identityId, key, filename] = splittedArray;
  } else if (splittedArray.length === 2) {
    [key, filename] = splittedArray;
  } else {
    [, identityId, key, filename] = splittedArray;
  }
  return {
    identityId,
    key: `${key}/${filename}`,
    filename,
  };
};
const openLinkInNewWindow = (link) => {
  if (!link) return;
  window.open(link, '', 'height=600, width=600, scrollbars=auto');
};
const uploadWorkspaceLogoToS3 = async (file) => {
  const metadata = {
    workspace_id: store.state.Workspace.activeWorkspaceId,
  };
  const { key } = await Storage.put(`${uuid()}/${file.name}`, file, {
    contentType: file.type,
    level: 'public',
    cacheControl,
    metadata,
  });
  return key;
};

/**
 * Check if a Signed URL from AWS S3 is expired.
 * @param {string} url - Signed S3 URL
 */
const isS3ImageUrlExpired = (url) => {
  if (!url) return false;

  const urlParams = new URLSearchParams(url.split('?')[1]);
  const amzExpires = urlParams.get('X-Amz-Expires');
  const amzDate = urlParams.get('X-Amz-Date');
  if (amzExpires && amzDate) {
    const now = Date.now();
    const expirationDate = new Date(amzDate.replace(/T/, ' ').replace(/Z/, '')).getTime();
    return now >= expirationDate + (parseInt(amzExpires) * 1000);
  }

  return false;
};

const beforeWorkspaceAdminpanelEnter = (to, from, next) => {
  if (store.getters['Workspace/isPersonalWorkspace']) {
    store.commit('openSnackBar', {
      title: 'Workspace admin panel does not work with personal workspace',
    });
    next({
      name: 'projects',
    });
    return;
  }
  next();
};
/**
 * Middleware function to check if the user can enter a community area.
 *
 * @param {Route} to - The target route object being navigated to.
 * @param {Route} from - The current route object the user is navigating away from.
 * @param {Function} next - The callback function to continue with the navigation.
 * @returns {void}
 */
const beforeVerifiedCommunityEnter = (to, from, next) => {
  const isVerified = store.getters['FeatureFlags/verifiedAreaListing'];
  if (isVerified) {
    // Continue with the navigation.
    next();
  } else {
    // Redirect back to the previous route.
    next(from);
  }
};
const beforeGetFileEnter = (to, from, next) => {
  const query = to?.query || {
  };
  const { force_update: forceUpdate, fileId: key } = query;
  if (key) {
    store.dispatch('getFileFromS3', {
      key,
      forceUpdate,
    });
  } else {
    next({
      name: 'projects',
    });
  }
};
function getLinkSharing(to) {
  if ((to?.redirectedFrom && (to?.redirectedFrom.includes(ROUTE_COMMUNITY_COLLECTION) || to?.redirectedFrom.includes(COLLECTION)) && !to?.redirectedFrom.includes(WORKSPACE))) {
    return store.getters['Workspace/getCorrectLinkWithWS'](to.redirectedFrom);
  }
  return false;
}
function getRedirectLinkFromQueryParams(query) {
  const { resourceType, resourceId } = query;
  if (!resourceType || !resourceId) {
    return null;
  }

  if (resourceType == WORKSPACE_PAGE_RESOURCE) {
    return store.getters['Workspace/getWorkspacePageRedirectLink'](resourceId);
  }

  return null;
}
const checkTutorialDisplay = () => {
  const user = store.state.userInfo;
  let showTutorial = false;
  if (user) {
    if (user['custom:newUser']) {
      showTutorial = [undefined, '0'].includes(user['custom:newUser']);
    } else {
      showTutorial = true;
    }
  }
  store.commit('setViewTutorial', showTutorial);
};
async function beforeRedirectNewUser(to, from, next) {
  if (sessionStorage.getItem('redirectFromQuery')) {
    sessionStorage.removeItem('redirectFromQuery');
    const path = getRedirectLinkFromQueryParams(from?.query);
    if (path) {
      next(path);
      return false;
    }
  }
  if (to.name === 'workspaces') {
    (['sign-up', 'login'].includes(from.name) || to.hash.includes('/projects#access_token')) && await store.dispatch('getCustomView', 'payment');
    if (sessionStorage.getItem('previousRouter')) {
      const previousRouter = JSON.parse(sessionStorage.getItem('previousRouter'));
      const path = getLinkSharing(previousRouter) || previousRouter;
      next(path);
      sessionStorage.removeItem('previousRouter');
      return false;
      //TODO: perhaps in next sprint we remove router workspace
    } else if (getLinkSharing(to)) {
      next(getLinkSharing(to));
      return false;
    }
    try {
      // this change is specific to Events/Exhibitions
      // make sure to change the id in router/index event-page
      {
        if (['event-login', 'event-sign-up'].includes(from.name)) {
          next({
            name: 'workspace-page',
            params: {
              pageId: '9ebd3105-66fd-48ab-a19b-ba38ad20d156',
              id: 'community',
              wId: store.state.Workspace.activeWorkspaceId,
            },
          });
          return;
        }
      }
      next({
        name: 'projects',
        query: to.query,
      });
    } catch (e) {
      next();
      await store.dispatch('handleError', e);
    }
  } else {
    if (to.name === 'projects') {
      if (['complete-new-password', 'login'].includes(from.name)) {
        if (sessionStorage.getItem('previousRouter')) {
          const previousRouter = JSON.parse(sessionStorage.getItem('previousRouter'));
          const path = getLinkSharing(previousRouter) || previousRouter;
          next(path);
          sessionStorage.removeItem('previousRouter');
          return false;
        }
      }
    }
    to.name !== 'projects' && store.commit('Workspace/setIsNeedRedirect', false);
    next();
  }
}

/**
 * Direct user after login.
 * - context: Component - Because we need to access router -- no useRoute/useRouter in Vue2.
 *
 * - We want to reduce loading time and number of redirects.
 * - This is faster than routing to '/workspaces then relying on
 *   'beforeRedirectNewUser()' to handle redirects.
 * - Ideally we remove the hacky & poorly named 'beforeRedirectNewUser()' but
 *   we should keep it in case of user links/bookmarks that use it.
 */
async function redirectAfterSuccessfulLogin(context) {
  const router = context.$router;
  const route = context.$route;
  try {
    // Login with query -- used by one-click login link from email to a page
    if (sessionStorage.getItem('redirectFromQuery')) {
      sessionStorage.removeItem('redirectFromQuery');
      const path = getRedirectLinkFromQueryParams(route.query);
      if (path) {
        return router.push(path).catch(() => {
        });
      }
    }

    // Redirected from a page because login/relogin was required.
    if (sessionStorage.getItem('previousRouter')) {
      const previousRouter = JSON.parse(sessionStorage.getItem('previousRouter'));
      const path = getLinkSharing(previousRouter) || previousRouter;
      router.push(path);
      sessionStorage.removeItem('previousRouter');
    }

    // If redirectedFrom was set for collections/community
    const lSharing = getLinkSharing(route);
    if (lSharing) {
      return router.push(lSharing).catch(() => {
      });
    }

    const agentPages = ['white-label', 'white-label-forgot', 'white-label-reset'];
    if (agentPages.includes(route.name)) {
      return router.push({
        path: '/account/personal-info',
      });
    }

    // Default -- login from actually visiting the login page first.
    return router.push({
      name: 'projects',
      params: {
        checkProfileComplete: true,
        showRouteLoader: true,
      },
    }).catch(() => {
    });

  } catch (err) {
    console.error(err);
    return router.push({
      name: 'projects',
      params: {
        showRouteLoader: true,
      },
    }).catch(() => {
    });
  }
}

/**
 * Decorator for asking and performing function
 * @param performFunction
 * @param askText
 * @returns {(function(): (function()))|*}
 */
const askAndPerform = function (performFunction, askText = 'Are you sure?', perform = true) {
  return async function () {
    if (typeof performFunction !== 'function') {
      return () => {
      };
    }
    if (!perform) {
      return;
    }
    const response = await Vue.prototype.$openConfirm({
      text: askText,
    });
    if (response) {
      return performFunction.apply(this, arguments);
    }
    return () => {
    };
  };
};
const checkUnsavedDataBeforeLeave = debounce((next) => {
  const isConfirm = confirm(`
    Leave page?
    Changes you made may not be saved.
  `);
  if (!isConfirm) {
    next(false);
  } else {
    next();
  }
}, 400);
/**
 * @param {String} path
 * @returns String
 */
const getFullLinkForWorkspaces = (path = '') => {
  return `/workspace/${store.state.Workspace.activeWorkspaceId}/${path}`;
};
const isEmail = string => {
  // eslint-disable-next-line
  return string.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi);
};
const isValidEmail = email => {
  if (!email) {
    return false;
  }
  const isStringEmail = typeof email === 'string' || typeof email === 'number';
  if (isStringEmail) {
    return isEmail(email);
  }
  return isEmail(email.email);
}
;
const hasAccess = (acceptableRole, role) => {
  if (isNaN(acceptableRole)) {
    acceptableRole = SHARED_PERMISSIONS_LEVEL[acceptableRole];
  }
  return acceptableRole <= SHARED_PERMISSIONS_LEVEL[role];
};
const hasMinAccess = minRole => role => {
  if (Array.isArray(role)) {
    return hasAccessV2(role, ROLES_BY_TYPE[minRole]);
  }
  return hasAccess(minRole, role);
};
const hasEditorAccess = hasMinAccess(TYPE_EDITOR);
const hasCreatorAccess = hasMinAccess(TYPE_CREATOR);
const hasCreatorAdminAccess = hasMinAccess(TYPE_CREATOR_ADMIN);
const hasCollaboratorAccess = hasMinAccess(TYPE_COLLABORATOR);
const hasViewerAccess = hasMinAccess(TYPE_VIEWER);
const equalsAccess = (accessLevel, role) => {
  if (isNaN(accessLevel)) {
    accessLevel = SHARED_PERMISSIONS_LEVEL[accessLevel];
  }
  return accessLevel === SHARED_PERMISSIONS_LEVEL[role];
};
const isRole = minRole => role => equalsAccess(minRole, role);
const isOwner = isRole(TYPE_OWNER);
const isCreatorAdmin = isRole(TYPE_CREATOR_ADMIN);
const isFollower = isRole(TYPE_FOLLOWER);
const isFollowerV2 = hasMinAccess(TYPE_FOLLOWER);
const lastUniqBy = iteratee => flow(
  partialRight(keyBy, iteratee),
  values
);
const lastUniqById = lastUniqBy('id');
const copyToClipBoard = (dataToCopy, text = 'Copied') => {
  const el = document.createElement('textarea');
  el.addEventListener('focusin', e => e.stopPropagation());
  el.value = dataToCopy;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  store.commit('openSnackBar', {
    text,
  });
};
const referenceSortBy = (referenceArray, secondArray) => {
  let referenceObject = {
  };
  for (let i = 0; i < referenceArray.length; i++) {
    referenceObject[referenceArray[i]] = i;
  }
  const newArray = cloneDeep(secondArray);
  return newArray.sort((a, b) => referenceObject[a] - referenceObject[b]);
};
const applyChangesToAnotherArray = (firstArray, secondArray, indexes) => {
  const copiedArray = secondArray;
  const [oldItemIndex, newItemIndex] = [indexes[1] - 1, indexes[0] - 1];
  const [newItem] = [firstArray[newItemIndex]];
  const indexToCount = newItemIndex - oldItemIndex;
  let newIndex = 0;
  if (indexToCount < 0) {
    newIndex = newItemIndex + 1;
  } else if (indexToCount > 0) {
    newIndex = newItemIndex - 1;
  }
  let itemIndexToChoose = newIndex >= 2 ? newIndex : 3;
  const itemToReplace = firstArray[itemIndexToChoose];
  const addedItemIndex = secondArray.indexOf(itemToReplace);
  copiedArray.splice(secondArray.indexOf(newItem), 1);
  addedItemIndex == secondArray.length - 1 ? copiedArray.push(newItem) : copiedArray.splice(addedItemIndex, 0, newItem);
  return copiedArray;
};
const getAreaUnitTitleByValue = val => {
  return AREA_UNITS_MAPPING.find(({ value }) => value === val)?.title || FEET_TITLE;
};
const accessHelper = (allowed, reason = '') => ({
  allowed: Boolean(allowed),
  ...!allowed && {
    reason,
  },
});
const isLimitOfProducts = ({ productsCount = null, addedProductsQty = 1 } = {
}) => {
  return productsCount + addedProductsQty > COLLECTION_PRODUCTS_LIMIT;
};
const setTitleToLimitOfProducts = ({ productsCount = null, addedProductsQty = 1 } = {
}) => {
  const accordingToLimit = `according to the limit of ${COLLECTION_PRODUCTS_LIMIT} products`;
  const oneProductDiffTitle = `Collections are limited to max of ${COLLECTION_PRODUCTS_LIMIT} products`;
  const diffBtwLimitAndProductsCount = COLLECTION_PRODUCTS_LIMIT - productsCount;
  const severalProductsDiffTitle = `${oneProductDiffTitle}.
    You can only add ${Math.abs(COLLECTION_PRODUCTS_LIMIT - productsCount)} product(s) to current collection, ${accordingToLimit}`;
  if (addedProductsQty > 1 && diffBtwLimitAndProductsCount > 0) {
    return severalProductsDiffTitle;
  }
  return oneProductDiffTitle;
};
const sortHelper = (viewId, sortBy, sortOrder = SORT_TOP) => ({
  viewId,
  sortBy,
  sortOrder,
});
const convertKbToMb = (size = 0, nameType = '') => {
  let mb = (+size / (1024 ** 2)).toFixed(2);
  if (nameType) mb += ' ' + nameType;
  return mb;
};
const isPassedDeadlineDay = (date, days) => {
  const now = new Date();
  const uploadDate = new Date(date);
  const deadline = new Date().setDate(uploadDate.getDate() + days);

  return now > deadline;
};
const fileSave = (file) => {
  const blob = new Blob([file.data], {
    type: `${file.type};charset=utf-8`,
  });
  FileSaver.saveAs(blob, file.name);
};
const formatValue = (val) => {
  const formatHelper = (value) => {
    if (typeof value !== 'string') return value;
    const trimmedVal = value.trim();
    return ['', '\b'].includes(trimmedVal) ? '' : value;
  };
  if (Array.isArray(val)) {
    return val.reduce((result, option) => {
      const v = formatHelper(option);
      if (!v) return result;
      return [...result, v];
    }, []);
  }
  return formatHelper(val);
};
const getCoordinates = el => el?.getBoundingClientRect?.bind(el);
const getElementCoordinates = el => {
  const getElCoordinates = getCoordinates(el);
  if (typeof getElCoordinates !== 'function') {
    return {
      x: 0,
      y: 0,
    };
  }
  return getElCoordinates();
};
const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
const waitTimeout = delay =>
  new Promise(resolve => setTimeout(resolve, delay));

const NUMBER_WITH_ONE_DOT_REGEX = /\d+\.?\d*/g;

/**
 * Because our data is really dirty, we need this to extract numbers from data in Projects Table.
 * Examples:
 * 1ft => 1
 * 2.5cm => 2.5
 *
 * Empty string and non-string types (excluding numbers) results in 0.
 */
function extractNumberFromString(str) {
  if (typeof str === 'number') return str;
  if (typeof str !== 'string' || !str) return 0;

  const matches = str.match(NUMBER_WITH_ONE_DOT_REGEX);
  if (matches) {
    const numberStr = matches.join('');
    const number = parseFloat(numberStr);
    return isNaN(number) ? 0 : number;
  }
  return 0;
}

const convertStringToArray = (str) => {
  if (!str) return [];
  if (Array.isArray(str)) return str;
  try {
    return JSON.parse(str);
  } catch (e) {
    console.warn('Error converting str to arr', e);
    return [str];
  }
};

function isUserProfileComplete(userData) {
  return PROFILE_REQUIRED_FIELDS.some(({ value }) => !userData?.[value]);
}
function missingUserProfileFields(userData) {
  const missing = [];
  for (const field of PROFILE_REQUIRED_FIELDS) {
    if (!userData?.[field.value]) missing.push(field.title);
  }
  return missing;
}

export {
  extractNumberFromString,
  convertKbToMb,
  applyChangesToAnotherArray,
  adjustImageSize,
  formatCurrency,
  getViewFromStorage,
  setViewInStorage,
  parseStorageKey,
  isS3ImageUrlExpired,
  openLinkInNewWindow,
  uploadWorkspaceLogoToS3,
  beforeWorkspaceAdminpanelEnter,
  askAndPerform,
  isEmail,
  isValidEmail,
  getFullLinkForWorkspaces,
  beforeGetFileEnter,
  hasAccess,
  equalsAccess,
  lastUniqBy,
  lastUniqById,
  isRole,
  isOwner,
  hasEditorAccess,
  hasCreatorAccess,
  hasCreatorAdminAccess,
  hasCollaboratorAccess,
  isFollower,
  isCreatorAdmin,
  beforeRedirectNewUser,
  redirectAfterSuccessfulLogin,
  copyToClipBoard,
  calculateAspectRatioFit,
  getURLSearchParams,
  referenceSortBy,
  getAreaUnitTitleByValue,
  accessHelper,
  isIdenticalIdArr,
  isLimitOfProducts,
  setTitleToLimitOfProducts,
  sortHelper,
  extractUserTeamRole,
  isPassedDeadlineDay,
  isFollowerV2,
  hasViewerAccess,
  filterHeadersGroup,
  fileSave,
  formatValue,
  getElementCoordinates,
  getCoordinates,
  addSearchParams,
  isUrlType,
  waitTimeout,
  genRanHex,
  checkTutorialDisplay,
  checkUnsavedDataBeforeLeave,
  beforeVerifiedCommunityEnter,
  convertStringToArray,
  isUserProfileComplete,
  missingUserProfileFields,
};
