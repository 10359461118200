<template>
  <div class="px-0">
    <v-tooltip
      :color="colorTooltip"
      :disabled="!isDisabled || !showTooltip"
      content-class="btn-tooltip"
      :top="top"
      :right="right"
      :left="left"
      :bottom="bottom">
      <template #activator="{ on }">
        <slot
          name="buttonWrapper"
          :btnAttrs="$attrs"
          :btnListeners="mergeAttrs($listeners, on)">
          <!-- $listeners is removed in Vue 3.x and is included inside $attrs -->
          <div
            class="d-block"
            v-on="on">
            <v-btn
              :class="btnClass"
              :disabled="isDisabled"
              v-bind="$attrs"
              v-on="mergeAttrs($listeners, on)">
              <!-- pass through normal slots -->
              <template
                v-for="(_, name) in $slots"
                v-slot:[name]>
                <slot :name="name" />
              </template>
            </v-btn>
          </div>
        </slot>
      </template>
      <span v-if="!showCustomTooltipTitle">{{ textToRendering }}</span>
      <span
        v-else
        v-safe-html="textToRendering" />
    </v-tooltip>
  </div>
</template>
<script>
import appBtnMixin from '@/mixins/appBtnMixin';
export default {
  name: 'AppBtn',
  mixins: [appBtnMixin],
  inheritAttrs: false,
  computed: {
    textToRendering() {
      const { text, reasonText } = this;
      return text || reasonText;
    },
  },
};
</script>
<style scoped lang="scss">
</style>
