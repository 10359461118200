<template>
  <v-row class="main-app-banner">
    <p
      class="mb-0 mainBlue--text">
      {{ maintenanceMessage }}
    </p>
    <v-icon
      class="main-app-banner__close-icon"
      style=""
      @click="$emit('close')">
      mdi-close
    </v-icon>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AppMaintenanceBanner',
  computed: {
    ...mapGetters('FeatureFlags', ['maintenanceMessage']),
  },
};
</script>
