<template>
  <div class="card-carousel">
    <v-row
      class="card-img mt-0"
      justify="center">
      <img
        v-if="isImage"
        :key="currentImage"
        :src="currentImage"
        :alt="images[activeImage].filename"
        class="img-screen">
      <embed
        v-else
        :key="key"
        :src="currentImage"
        type="application/pdf">
      <v-row
        v-if="isMoreThanOneImage"
        class="actions mx-0">
        <v-icon
          x-large
          color="#CACACA"
          class="actions__left"
          @click="prevImage">
          mdi-arrow-left-circle-outline
        </v-icon>
        <v-icon
          x-large
          color="#CACACA"
          class="actions__right"
          @click="nextImage">
          mdi-arrow-right-circle-outline
        </v-icon>
      </v-row>
    </v-row>
    <div class="card-carousel-description">
      <div class="d-flex justify-space-between mb-2">
        <div>
          &nbsp;
        </div>
        <div class="d-flex">
          <div>
            <div class="mt-1">
              <span
                v-if="isMoreThanOneImage"
                class="image-index">
                {{ activeImage + 1 }} of {{ images.length }}
              </span>
            </div>
          </div>
        </div>
        <v-btn
          :href="currentImage"
          class="inverted-btn"
          color="grey"
          outlined
          :loading="showSpinner"
          @click="downloadItem(currentImage, $event)">
          Download
        </v-btn>
      </div>
      <v-row
        v-if="isMoreThanOneImage"
        class="thumbnails ma-0"
        justify="center">
        <div
          v-for="(image, index) in images"
          :key="image.id"
          :class="['thumbnail-image', (activeImage == index) ? 'active' : '']"
          @click="activateImage(index)">
          <img
            v-if="getSafeSmallImage(image)"
            :src="getSafeSmallImage(image)"
            :alt="images[activeImage].filename">
          <div v-else>
            <v-icon size="50">
              mdi-image
            </v-icon>
          </div>
        </div>
      </v-row>
    </div>
  </div>
</template>
<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex';
import { Storage } from 'aws-amplify';
import { parseStorageKey } from '@/utils';
import fileIcon from '@/assets/icons/file-icon.svg';
import pdfIcon from '@/assets/icons/pdf-icon.svg';
export default {
  name: 'AppFileImagePreviewCarousel',
  props: {
    startingImage: {
      type: Number,
      default: null,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeImage: 0,
      key: 0,
    };
  },
  computed: {
    ...mapState(['showSpinner']),
    currentImage() {
      let image = this.images[this.activeImage].url;
      if (this.isImage) {
        image = this.images[this.activeImage].thumbnails?.full?.url || this.images[this.activeImage].url;
      } else {
        // eslint-disable-next-line
        this.key = this.key + 1;
      }
      return image;
    },
    isImage() {
      return this.images[this.activeImage]?.type?.includes('image') || false;
    },
    isMoreThanOneImage() {
      return this.images.length > 1;
    },
    imageIconSrc() {
      return this.isImage ? fileIcon : pdfIcon;
    },
  },
  created() {
    if (this.startingImage
      && this.startingImage >= 0
      && this.startingImage < this.images.length) {
      this.activeImage = this.startingImage;
    }
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions(['downloadFileByBlob']),
    getSafeSmallImage(image) {
      return image?.thumbnails?.small?.url;
    },
    async downloadItem(url, e) {
      this.spinner(true);
      const { url: customUrl, key: customKey } = this.images[this.activeImage];
      if (customKey && customUrl) {
        let item = this.images[this.activeImage];
        e.preventDefault();
        const { key, identityId, filename } = parseStorageKey(item.key);
        const url = await Storage.get(key, {
          level: 'protected',
          identityId,
        });
        this.downloadFileByBlob({
          type: item.type,
          filename,
          url,
        });
      } else {
        if (!this.images[this.activeImage].allowDefault) {
          this.spinner(true);
          e.preventDefault();
          const { type, filename } = this.images[this.activeImage];
          this.downloadFileByBlob({
            type,
            filename,
            url,
          });
        }
      }
    },
    nextImage() {
      let active = this.activeImage + 1;
      if (active >= this.images.length) {
        active = 0;
      }
      this.activateImage(active);
    },
    prevImage() {
      let active = this.activeImage - 1;
      if (active < 0) {
        active = this.images.length - 1;
      }
      this.activateImage(active);
    },
    activateImage(imageIndex) {
      this.activeImage = imageIndex;
    },
  },
};
</script>
<style lang="scss" scoped>
.card-carousel {
  user-select: none;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.thumbnail-image:not(:last-child) {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  margin-right: 40px;
  max-height: 200px;
  max-width: 130px;
}
.thumbnail-image > img {
  width: 100%;
  height: auto;
  transition: all 250ms;
  opacity: 0.6;
  background: white;
  border-radius: 5px;
}
.thumbnail-image:hover > img,
.thumbnail-image.active > img {
  transform: scale(1.1);
  opacity: 1;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.5);
}
.card-img {
  position: relative;
  margin-bottom: 15px;
  img {
    align-self: center;
    display: block;
    margin: 0 auto;
    max-width: 70%;
    height: auto;
    max-height: 58vh;
  }
  embed {
    width: 70%;
    height: auto;
  }
}
.actions {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .v-icon {
    transition: all 250ms;
    &:hover {
      color: #fff !important;
      transform: scale(1.3);
    }
  }
  & .actions__left {
    left: 10%;
  }
  & .actions__right {
    right: 10%;
  }
}
.card-carousel-description {
  padding: 20px 5vw;
  background-color: var(--v-darkGrey-base);
  & .image-index {
    font-size: 14px;
    font-weight: 300;
  }
}
.img-screen {
  border-radius: 5px;
}
</style>
