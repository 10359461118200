import {
  PROJECT_SORT_ITEMS,
  SORT_ALPHABETICAL_FIELDS,
} from '@/constants/sortable';
import router from '@/router';
import { orderBy } from 'lodash';
import {
  ACTIVE, ARCHIVED,
} from '@/constants';
export const getters = {
  getSortedItem(state, getters, rootState, rootGetters) {
    const mode = rootGetters['UserProfile/projectSortingMode'];
    const isAscending = rootGetters['UserProfile/projectSortingModeAscending'];
    return PROJECT_SORT_ITEMS.find(item => {
      const { sortingMode, ascending = true } = item || {
      };
      return sortingMode == mode && ascending === isAscending;
    }) || PROJECT_SORT_ITEMS[0];
  },
  getSortedMode(state, getters) {
    return getters.getSortedItem?.sortingMode;
  },
  activeProjectsBySearchText(state, getters, rootState, rootGetters) {
    try {
      const { active, searchText } = state;
      const canAddProductToProject = rootGetters['UserRoles/canAddProductToProject'];
      let projects = active;
      if (searchText) {
        projects = active.filter(({ projectName }) => projectName.toLowerCase().includes(searchText.toLowerCase()));
      }
      const getProjectTitle = rootGetters['ProjectDetails/getProjectTitle'];
      return projects.map(project => {
        const { type: role, sourceLibrary } = project;
        const access = canAddProductToProject(role, sourceLibrary, router.currentRoute.params?.id);
        return {
          ...project,
          projectTitle: getProjectTitle(project),
          disabled: !access?.allowed,
          reason: access?.reason || '',
        };
      });
    } catch (e) {
      console.log(e);
    }
  },
  getProjectRelatedNextToken: (state) => (status) => {
    return state[`${status}NextToken`];
  },
  /**
   * Sort and filter projects based on the specified status and sorting criteria.
   *
   * @param {object} state - The Vuex state object.
   * @param {object} getters - The Vuex getters object.
   * @param {string} status - The status to filter projects by.
   * @returns {Array} - The sorted and filtered array of projects.
   */
  sortedProjects: (state, getters) => (status) => {
  // Destructure the status array from the state.
    const { [status]: statusArray } = state;

    // Destructure sorting criteria from getters.
    const { sortingMode: mode, nameInSchema: val, ascending = true } = getters.getSortedItem;

    // Destructure the tag from the state.groupByTags or provide an empty object.
    const {
      [ACTIVE]: { tag } = {
      },
    } = state.groupByTags || {
    };

    // Filter the projects based on the tag if it exists.
    const filteredArray = tag ? statusArray.filter(({ tags = [] }) => tags.includes(tag)) : statusArray;

    // Check if the sorting mode is alphabetical.
    if (!SORT_ALPHABETICAL_FIELDS.includes(mode)) return filteredArray;

    // Determine the order of sorting.
    const orderOfSort = ascending ? 'asc' : 'desc';

    // Define a helper function to transform project property for sorting.
    const transformForSorting = (project) => project[val].toLowerCase();

    // Sort and return the filtered array.
    return orderBy(filteredArray, transformForSorting, orderOfSort);
  },
  sortedActiveProjects(state, getters) {
    return getters.sortedProjects(ACTIVE);
  },
  sortedArchivedProjects(state, getters) {
    return getters.sortedProjects(ARCHIVED);
  },
  getStarringViewIdByActiveTab(state) {
    return state.changeActiveTabView?.id || '';
  },
  getStarringViewTagByActiveTab(state) {
    return state.changeActiveTabView?.tags?.[0] || '';
  },
};
