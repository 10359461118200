import {
  ACTIVE, ARCHIVED,
} from '@/constants';
export const state = {
  staringFilteredViews: [],
  activeStaringFilteredViewId: '',
  changeActiveTabView: null,
  active: [],
  archived: [],
  activeNextToken: '',
  archivedNextToken: '',
  searchText: '',
  isFinishedGetOfProjects: false,
  filter: null,
  projectSpinner: 0,
  groupByTags: {
    active: {
      groupByTagsTrigger: false,
      status: ACTIVE,
      tag: '',
    },
    archived: {
      groupByTagsTrigger: false,
      status: ARCHIVED,
      tag: '',
    },
  },
  isFetching: {
    projectsList: false,
  },
};
